<template>
  <header
    class="py-2 md:py-4 relative"
    :class="[
      loading ? 'bg-blue-lightest' : 'bg-grey',
      !!$slots.headingRight ? 'pb-12 text-center md:text-left md:pr-20 md:pl-4' : 'text-center',
    ]"
  >
    <h2 class="text-white font-bold tracking-wide h-6 relative w-full md:w-auto">
      <span v-if="loading" />
      <slot v-else />
    </h2>
    <span
      v-if="!!$slots.headingRight && !loading"
      class="block w-full md:w-auto absolute right-1/2 md:right-2 xl:right-3 bottom-1 md:bottom-auto md:top-1/2 transform translate-x-1/2 md:translate-x-0 md:-translate-y-1/2"
    >
      <slot name="headingRight" />
    </span>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
