<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full h-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g>
      <path
        fill="none"
        stroke="currentColor"
        stroke-width="6"
        stroke-miterlimit="10"
        d="M254.824,130.164
		c0,69.247-56.112,125.365-125.367,125.365c-69.242,0-125.36-56.118-125.36-125.365c0-69.236,56.118-125.36,125.36-125.36
		C198.712,4.804,254.824,60.928,254.824,130.164z"
      />
      <g>
        <g>
          <defs>
            <path
              id="SVGID_1_"
              d="M254.824,130.164c0,69.247-56.112,125.365-125.367,125.365c-69.242,0-125.36-56.118-125.36-125.365
					c0-69.236,56.118-125.36,125.36-125.36C198.712,4.804,254.824,60.928,254.824,130.164z"
            />
          </defs>
          <clipPath id="SVGID_2_">
            <use
              xlink:href="#SVGID_1_"
              overflow="visible"
            />
          </clipPath>
          <g clip-path="url(#SVGID_2_)">
            <path
              fill="currentColor"
              d="M176.105,105.177c0,25.476-20.646,46.112-46.114,46.112c-25.47,0-46.121-20.637-46.121-46.112
					c0-25.47,20.65-46.114,46.121-46.114C155.461,59.063,176.105,79.707,176.105,105.177z"
            />
            <path
              fill="currentColor"
              d="M69.654,142.76c0,0,32.481,37.211,60.337,35.789c27.851-1.421,59.147-35.789,59.147-35.789
					s37.881,1.648,43.571,44.327c5.688,42.672-32.912,136.553-32.912,136.553H52.585c0,0-32.116-105.12-32.193-133.71
					C20.27,142.76,69.654,142.76,69.654,142.76z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>