
import { defineComponent, PropType, computed } from 'vue'
import { Alignment } from '@/types/base'


export default defineComponent({
  props: {
    align: {
      type: String as PropType<Alignment>,
      validator: (val: Alignment) => ['left', 'center', 'right'].includes(val),
      default: 'left',
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    textless: {
      type: Boolean,
      default: false,
    },
    stickyOnScroll: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      default: '',
    },
  },
  
  emits: ['sort'],
  
  setup (props, ctx) {
    const alignmentClass = computed(() => {
      if (props.align === 'center') {
        return 'text-center'
      }
      if (props.align === 'right') {
        return 'text-center'
      }
      return 'text-left'
    })

    const textlessClass = computed(() => props.textless ? '' : 'px-4 py-4 tracking-wide font-medium')

    const sortColumnName = computed(() => props.sort.replace(/^-/, ''))

    const isActive = computed(() => sortColumnName.value === props.name)

    const emitSortEvent = () => {
      ctx.emit('sort', (isActive.value && props.sort[0] !== '-' ? '-' : '') + props.name)
    }

    const stickyOnScrollClasses = computed(() => {
      if (!props.stickyOnScroll) return ''

      return 'sticky left-0 bg-grey bg-opacity-75'
    })

    return {
      alignmentClass,
      textlessClass,
      isActive,
      emitSortEvent,
      stickyOnScrollClasses,
    }
  },
})
