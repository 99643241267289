
import { defineComponent, PropType } from 'vue'
import { NavItem } from '@/types/base'

// Icons
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import PersonnelIcon from '@/components/icons/PersonnelIcon.vue'
import MasterRotaIcon from '@/components/icons/MasterRotaIcon.vue'
import DailyRotaIcon from '@/components/icons/DailyRotaIcon.vue'
import RequestsIcon from '@/components/icons/RequestsIcon.vue'
import ProfileIcon from '@/components/icons/ProfileIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'


export default defineComponent({
  components: {
    DashboardIcon,
    PersonnelIcon,
    MasterRotaIcon,
    DailyRotaIcon,
    RequestsIcon,
    ProfileIcon,
    LogoutIcon,
  },

  props: {
    navItem: {
      type: Object as PropType<NavItem>,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-drawer'],
  
  setup (props, ctx) {
    const closeDrawer = () => {
      if (props.expanded) {
        ctx.emit('close-drawer')
      }
    }

    return {
      activeClasses: 'bg-blue-dark',
      closeDrawer,
    }
  },
})
