<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="11"
      stroke-miterlimit="10"
      d="M204.561,129.906
	c0,41.305-33.479,74.781-74.774,74.781c-41.313,0-74.775-33.477-74.775-74.781c0-41.301,33.462-74.774,74.775-74.774
	C171.08,55.132,204.561,88.605,204.561,129.906z"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-width="11"
      stroke-miterlimit="10"
      d="M252.999,129.906
	c0,68.056-55.149,123.218-123.213,123.218c-68.055,0-123.213-55.162-123.213-123.218C6.572,61.855,61.73,6.693,129.786,6.693
	C197.85,6.693,252.999,61.855,252.999,129.906z"
    />
    <g enable-background="new    ">
      <path
        fill="currentColor"
        d="M121.526,170.771c0-5.04,3.5-8.681,8.26-8.681c5.041,0,8.26,3.641,8.26,8.681c0,4.899-3.219,8.68-8.26,8.68
		C124.887,179.45,121.526,175.67,121.526,170.771z M125.026,150.75l-1.96-67.199h13.44l-1.961,67.199H125.026z"
      />
    </g>
  </svg>
</template>