<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-auto"
    viewBox="0 0 172.673 20"
    enable-background="new 0 0 172.673 20"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M8.646,20H0.079V8.661h3.108v8.232h5.152c1.288,0,2.417-0.331,3.388-0.994
		c0.971-0.662,1.685-1.488,2.142-2.479c0.457-0.988,0.7-2.063,0.728-3.219c0.028-1.157-0.173-2.23-0.602-3.22
		c-0.43-0.989-1.139-1.815-2.128-2.478c-0.99-0.662-2.166-0.994-3.528-0.994h-8.26V0.4h8.568c1.363,0,2.618,0.224,3.766,0.672
		c1.148,0.448,2.095,1.05,2.842,1.806c0.746,0.756,1.386,1.624,1.918,2.604c0.532,0.98,0.858,2.012,0.98,3.094
		c0.121,1.083,0.13,2.166,0.028,3.248c-0.103,1.083-0.411,2.114-0.924,3.094c-0.514,0.98-1.144,1.849-1.89,2.604
		s-1.704,1.358-2.87,1.806C11.33,19.777,10.047,20,8.646,20z"
      />
      <path
        fill="currentColor"
        d="M35.919,20l-2.016-4.703h-9.268L22.647,20h-3.416l4.648-10.919h3.416l-1.288,3.024h6.552
		C29.423,4.75,27.761,0.849,27.575,0.4h3.416L39.335,20H35.919z"
      />
      <path
        fill="currentColor"
        d="M45.243,20h-3.108V0.4h3.108V20z"
      />
      <path
        fill="currentColor"
        d="M62.323,20h-14.28V0.4h3.108v16.492h11.172V20z"
      />
      <path
        fill="currentColor"
        d="M69.49,4.769h-3.808L62.658,0.4h3.78L69.49,4.769z M76.294,0.4h3.78l-7.14,10.276V20h-3.108V9.696
		L76.294,0.4z"
      />
      <path
        fill="currentColor"
        d="M105.078,20l-5.152-8.26h-4.228V20h-3.108V8.661h9.828h0.028c0.709,0,1.316-0.252,1.82-0.756
		c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82c-0.504-0.504-1.111-0.756-1.82-0.756h-9.856V0.4h9.828
		c1.512,0,2.814,0.523,3.906,1.568c1.092,1.046,1.685,2.315,1.778,3.808v0.028c0.056,1.4-0.35,2.655-1.218,3.766
		c-0.868,1.111-1.993,1.807-3.374,2.086L108.717,20H105.078z"
      />
      <path
        fill="currentColor"
        d="M120.954-0.104c1.4,0,2.734,0.271,4.004,0.812c1.269,0.542,2.366,1.274,3.29,2.198
		c0.924,0.924,1.656,2.021,2.198,3.29c0.542,1.27,0.812,2.604,0.812,4.004c0,1.867-0.462,3.588-1.386,5.167
		c-0.924,1.577-2.175,2.827-3.752,3.752c-1.578,0.924-3.3,1.385-5.166,1.385c-1.867,0-3.594-0.461-5.18-1.385
		c-1.587-0.925-2.837-2.175-3.752-3.752c-0.915-1.578-1.372-3.3-1.372-5.167s0.458-3.588,1.372-5.166
		c0.915-1.577,2.165-2.828,3.752-3.752C117.36,0.358,119.087-0.104,120.954-0.104z M125.965,15.213
		c1.381-1.381,2.072-3.053,2.072-5.012c0-1.96-0.691-3.63-2.072-5.012c-1.381-1.381-3.052-2.072-5.012-2.072
		s-3.631,0.69-5.012,2.072c-1.381,1.382-2.072,3.052-2.072,5.012c0,1.959,0.69,3.631,2.072,5.012s3.052,2.072,5.012,2.072
		S124.584,16.594,125.965,15.213z"
      />
      <path
        fill="currentColor"
        d="M150.046,3.508h-15.988V0.4h15.988V3.508z M143.606,20h-3.108V6.617h3.108V20z"
      />
      <path
        fill="currentColor"
        d="M169.533,20l-2.016-4.703h-9.268L156.261,20h-3.416l4.648-10.919h3.416l-1.288,3.024h6.552
		c-3.136-7.354-4.798-11.256-4.984-11.704h3.416l8.344,19.6H169.533z"
      />
    </g>
  </svg>
</template>