// Imported types
import { State } from './'
import { ErrorMap } from '@/types/ajax'
import { AuthSuccessResponse } from '@/types/auth'

/**
 * Mutation Types
 */
export enum _MutationTypes {
  SET_AUTH_INIT = 'SET_AUTH_INIT',
  SET_AUTH_DATA = 'SET_AUTH_DATA',
  SET_AUTH_ERROR = 'SET_AUTH_ERROR',
  CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA',
  SET_ATTEMPTED_AUTO_LOGIN = 'SET_ATTEMPTED_AUTO_LOGIN',
  SET_INITIAL_PATH = 'SET_INITIAL_PATH',
  CLEAR_INITIAL_PATH = 'CLEAR_INITIAL_PATH',
}

export default {
  [_MutationTypes.SET_AUTH_INIT](state: State) {
    state.requesting = true
  },

  [_MutationTypes.SET_AUTH_DATA](state: State, payload: AuthSuccessResponse) {
    state.data.access_token = payload.data.access_token
    state.data.expires_in = payload.data.expires_in
    state.requesting = false
    state.error = null
  },

  [_MutationTypes.CLEAR_AUTH_DATA](state: State) {
    state.data.access_token = ''
    state.data.expires_in = null
    state.requesting = false
    state.error = null
  },

  [_MutationTypes.SET_AUTH_ERROR](state: State, payload: ErrorMap) {
    state.data.access_token = ''
    state.data.expires_in = null
    state.requesting = false
    state.error = payload
  },

  [_MutationTypes.SET_INITIAL_PATH](state: State, payload: string) {
    state.initialPath = payload
  },

  [_MutationTypes.CLEAR_INITIAL_PATH](state: State) {
    state.initialPath = ''
  },

  [_MutationTypes.SET_ATTEMPTED_AUTO_LOGIN](state: State) {
    state.attemptedAutoLogin = true
  },
}