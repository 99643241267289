
import { defineComponent } from 'vue'
import SuccessIcon from '@/components/icons/toast/SuccessIcon.vue'

export default defineComponent({
  components: {
    SuccessIcon,
  },

  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },
})
