<template>
  <AuthFormWrapper>
    <template #formTitle>
      Sign in to your account
    </template>

    <form
      @submit="onSubmit"
      class="w-full mt-12 lg:mt-16"
    >
      <BaseErrorMessage 
        v-if="submissionError"
        extend-wrapper-classes="-mt-6 mb-4"
      >
        {{ submissionError }}
      </BaseErrorMessage>
      <fieldset :disabled="working">
        <!-- Fields -->
        <VTextInput 
          name="email"
          type="email"
          label="Email Address"
          maxlength="100"
        />
        <VTextInput 
          name="password"
          type="password"
          label="Password"
          maxlength="50"
        />
      </fieldset>

      <!-- Additional Options -->
      <div class="flex justify-end text-gray-400 my-4 text-sm xl:text-base">
        <RouterLink
          :to="{ name: 'forgot-password' }"
          class="hover:underline"
        >
          Forgot your password?
        </RouterLink>
      </div>

      <!-- Submit -->
      <BaseButton
        theme="auth"
        size="full"
        type="submit"
        :working="working"
      >
        Sign In
      </BaseButton>
    </form>
  </AuthFormWrapper>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import useToasts from '@/hooks/useToasts'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Store
import { useStore } from 'vuex'
import { ActionTypes as AuthActions } from '@/store/auth/actions'
import { GetterTypes as AuthGetters } from '@/store/auth/getters'
import { GetterTypes as UserGetters } from '@/store/user/getters'

// Types
import { Authable } from '@/types/auth'

// Utils
import parseErrorMap from '@/utils/parseErrorMap'

export default defineComponent({
  setup () {
    const store = useStore()
    const router = useRouter()
    const dispatch = useToasts()

    const submissionError = computed(() => {
      return parseErrorMap(store.getters[AuthGetters.ERROR] || store.getters[UserGetters.ERROR])
    })

    const working = computed(() => {
      return store.getters[AuthGetters.REQUESTING] || store.getters[UserGetters.REQUESTING]
    })

    const authSchema: yup.ObjectSchema<Authable> = yup.object({
      email: yup
        .string()
        .email('Please provide a valid email.')
        .required('This field is required.'),
      password: yup
        .string()
        .required('This field is required.'),
    }).defined()

    const { handleSubmit } = useForm({
      validationSchema:  authSchema,
    })

    const onSubmit = handleSubmit((values) => {
      // attempt login
      store.dispatch(AuthActions.ATTEMPT_LOGIN, values)
        .then(() => {
          if (store.getters[AuthGetters.IS_AUTH]) {
            dispatch.successToast(
              `Welcome ${store.getters[UserGetters.USER].name}`,
              'Login Success',
            )
            router.push({ name: 'dashboard' })
          }
        })
    });

    return {
      working,
      onSubmit,
      submissionError,
    };
  },
})
</script>