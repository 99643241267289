import store from '@/store'
import { computed } from 'vue'
import { GetterTypes as AuthGetters } from '@/store/auth/getters'
import { GetterTypes as UserGetters } from '@/store/user/getters'

// expose a hook that allows easy utilisation of user role. 
// USAGE: const role = useRole()
export default function useToasts() {
  const isAuth = computed(() => store.getters[AuthGetters.IS_AUTH])

  const role = computed(() => {
    if (!isAuth.value) return null
    return store.getters[UserGetters.ROLE]
  })

  const isStaff = computed(() => (role.value && role.value === 'staff'))

  const isDesk = computed(() => (role.value && role.value === 'desk'))

  const isSuper = computed(() => (role.value && role.value === 'super'))

  const isAdmin = computed(() => (role.value && role.value === 'admin') || isSuper.value)

  return {
    isStaff,
    isDesk,
    isAdmin,
    isSuper,
  }
}