<template>
  <teleport to="body">
    <div
      class="origin-top-right fixed right-4 top-10 md:top-16 mt-2 w-56 rounded-md shadow-lg transition ease-out bg-white"
      @mouseleave="closeMenu"
      :class="[
        userMenuOpen
          ? 'opacity-100 scale-100 duration-200'
          : 'opacity-0 scale-95 pointer-events-none duration-100'
      ]"
    >
      <div class="flex items-center justify-end bg-blue-medium p-3 rounded-t-md">
        <section class="text-right italic">
          <h4 class="font-bold text-white leading-tight">
            {{ userName }}
          </h4>
          <p class="font-light text-sm text-white">
            {{ userRole }}
          </p>
        </section>
        <div class="w-12 h-12 text-white ml-4">
          <HeaderProfileIcon />
        </div>
      </div>
      
      <div
        class="pb-1 rounded-md"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="user-menu"
        @click="$emit('clicked')"
      >
        <RouterLink
          :to="{ name: 'my-profile' }"
          class="cursor-pointer flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150 border-t border-gray-300"
          role="menuitem"
        >
          <span class="w-3 h-3 flex mr-3 text-blue-dark">
            <ProfileIconThick />
          </span>
          View profile
        </RouterLink>
        <RouterLink
          :to="{ name: 'logout' }"
          class="cursor-pointer flex items-center px-4 py-2 text-sm hover:bg-gray-100 transition ease-in-out duration-150 border-t border-gray-300"
          role="menuitem"
        >
          <span class="w-3 h-3 flex mr-3  text-blue-dark">
            <LogoutIconThick />
          </span>
          Log out
        </RouterLink>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

// Store
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import { 
  GetterTypes as BaseGetters,
  ActionTypes as BaseActions,
} from '@/store/base'

// Utils
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

// Icons
import HeaderProfileIcon from '@/components/icons/HeaderProfileIcon.vue'
import ProfileIconThick from '@/components/icons/ProfileIconThick.vue'
import LogoutIconThick from '@/components/icons/LogoutIconThick.vue'

export default defineComponent({
  components: {
    HeaderProfileIcon,
    ProfileIconThick,
    LogoutIconThick,
  },

  emits: ['clicked'],

  setup () {
    const store = useStore()

    const user = computed(() => store.getters[UserGetters.USER])

    const userMenuOpen = computed(() => store.getters[BaseGetters.USER_MENU_OPEN])

    const closeMenu = () => {
      store.dispatch(BaseActions.HIDE_USER_MENU)
    }

    return {
      userId: user.value.id,
      userName: user.value.name,
      userRole: capitalizeFirstLetter(user.value.role),
      userMenuOpen,
      closeMenu,
    }
  },
})
</script>

<style scoped>

</style>