<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <path
      fill="currentColor"
      d="M194.456,217.569l-3.733-27.995c-15.458,15.885-37.056,25.766-60.973,25.766
	c-46.994,0-85.09-38.095-85.09-85.09c0-46.994,38.096-85.09,85.09-85.09c46.995,0,85.09,38.096,85.09,85.09
	c0,9.646-1.629,18.908-4.586,27.553l5.494,33.686l31.284-8.05c7.364-16.214,11.468-34.222,11.468-53.188
	C258.5,59.144,200.856,1.5,129.75,1.5S1,59.144,1,130.25S58.644,259,129.75,259c42.264,0,79.77-20.365,103.244-51.815
	L194.456,217.569z"
    />
  </svg>
</template>