import store from '@/store'
import { ActionTypes as AuthActions } from '@/store/auth/actions'
import { GetterTypes as AuthGetters } from '@/store/auth/getters'
import { ActionTypes as ToastActions } from '@/store/toast'

// Types
import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { ToastNotification } from '@/types/toast'

// Utils
import parseErrorMap from '@/utils/parseErrorMap'

// Adapted 'refresh attempt on 401' from https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f

const shouldIntercept = (error: AxiosError) => {
  try {
      return error.response?.status === 401
        && !store.getters[AuthGetters.REQUESTING] // not a request from /login 
        && store.getters[AuthGetters.ATTEMPTED_AUTO_LOGIN] // already tried to autologin
  } catch (e) {
      return false
  }
}

const attachTokenToRequest = (request: AxiosRequestConfig, token: string) => {
  request.headers['Authorization'] = 'Bearer ' + token;
};

export default (axiosClient: AxiosInstance, customOptions = {}) => {
  let isRefreshing = false
  let failedQueue: PromiseConstructor[] | [] = []

  const options = {
      shouldIntercept,
      attachTokenToRequest,
      ...customOptions,
  };

  const processQueue = (error: AxiosError | null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error)
        store.dispatch(ToastActions.SHOW, {
          type: 'error',
          content: parseErrorMap(error.response?.data),
        } as ToastNotification)
      } else {
        prom.resolve()
      }
    });

    failedQueue = []
  }

  const interceptor = (error: AxiosError & { config: { _retry?: boolean; _queued?: boolean } }) => {
    if (!options.shouldIntercept(error)) {
      return Promise.reject(error)
    }

    if (error.config._retry || error.config._queued) {
      return Promise.reject(error)
    }

    const originalRequest = error.config;
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        const queueItem = { resolve, reject } as PromiseConstructor
        failedQueue = [...failedQueue, queueItem]
      })
        .then(() => {
          originalRequest._queued = true
          options.attachTokenToRequest(originalRequest, store.getters[AuthGetters.TOKEN])
          return axiosClient.request(originalRequest)
        })
        .catch(() => {
          // Ignore refresh token request's "err" and return actual "error" for the original request
          return Promise.reject(error)
        })
    }

    originalRequest._retry = true
    isRefreshing = true

    return new Promise((resolve, reject) => {
      store.dispatch(AuthActions.ATTEMPT_REFRESH)
        .then(() => {
          options.attachTokenToRequest(originalRequest, store.getters[AuthGetters.TOKEN])
          processQueue(null)
          resolve(axiosClient.request(originalRequest))
        })
        .catch((err) => {
          processQueue(err)
          reject(err)
        })
        .finally(() => {
          isRefreshing = false
        })
    });
  };

  axiosClient.interceptors.response.use(undefined, interceptor)
}