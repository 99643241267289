import { Module } from 'vuex'
import { State as RootState } from '@/store'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// Types
import { Requestable } from '@/types/ajax'	
import { UserSuccessResponse } from '@/types/users'	

/**
 * State Type
 */
export type State = { user: UserSuccessResponse | null } & Requestable

export const userModule: Module<State, RootState> = {
  namespaced: true,

	state: () =>  ({
		user: null,
		requesting: false,
		error: null,
  }),
  
	mutations,
  
	getters,

	actions,
}


