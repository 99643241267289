<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full"
    viewBox="0 0 1260 103"
    enable-background="new 0 0 1260 103"
    xml:space="preserve"
  >
    <g
      id="Layer_1"
      display="none"
    >
      <rect
        x="-11"
        y="-23.5"
        display="inline"
        width="1285"
        height="150"
      />
    </g>
    <g id="Layer_2">
      <g>
        <path
          fill="currentColor"
          d="M62.994,100.498L37.418,59.493H16.429v41.005H1V44.203h48.789h0.139c3.521,0,6.533-1.251,9.035-3.753
			s3.753-5.512,3.753-9.035c0-3.521-1.251-6.533-3.753-9.035s-5.515-3.753-9.035-3.753H1V3.198h48.789
			c7.506,0,13.97,2.595,19.391,7.784c5.421,5.191,8.362,11.492,8.827,18.904v0.139c0.278,6.95-1.738,13.184-6.046,18.696
			c-4.309,5.514-9.893,8.965-16.75,10.355l25.854,41.422H62.994z"
        />
        <path
          fill="currentColor"
          d="M141.808,0.696c6.95,0,13.574,1.344,19.877,4.031c6.301,2.689,11.746,6.325,16.333,10.912
			s8.223,10.032,10.912,16.333c2.687,6.303,4.031,12.927,4.031,19.876c0,9.268-2.293,17.816-6.88,25.646
			c-4.587,7.831-10.796,14.038-18.626,18.625c-7.832,4.588-16.38,6.881-25.646,6.881c-9.268,0-17.84-2.293-25.715-6.881
			c-7.877-4.587-14.087-10.794-18.626-18.625c-4.542-7.83-6.811-16.379-6.811-25.646c0-9.265,2.27-17.813,6.811-25.645
			c4.539-7.83,10.749-14.039,18.626-18.626C123.968,2.99,132.54,0.696,141.808,0.696z M166.688,76.729
			c6.857-6.855,10.286-15.15,10.286-24.881c0-9.729-3.429-18.022-10.286-24.88c-6.859-6.857-15.151-10.286-24.881-10.286
			s-18.024,3.429-24.881,10.286c-6.859,6.858-10.286,15.151-10.286,24.88c0,9.73,3.427,18.025,10.286,24.881
			c6.856,6.859,15.151,10.287,24.881,10.287S159.83,83.588,166.688,76.729z"
        />
        <path
          fill="currentColor"
          d="M286.228,18.627h-79.369V3.198h79.369V18.627z M254.258,100.498h-15.429V34.056h15.429V100.498z"
        />
        <path
          fill="currentColor"
          d="M382.971,100.498l-10.008-23.352h-46.009l-9.869,23.352h-16.958l23.074-54.21h16.958L333.765,61.3h32.526
			c-15.568-36.509-23.816-55.878-24.742-58.102h16.958l41.422,97.3H382.971z"
        />
        <path
          fill="currentColor"
          d="M544.902,100.498l-10.008-23.352h-46.009l-9.869,23.352h-16.958l23.074-54.21h16.958L495.696,61.3h32.526
			C512.654,24.791,504.405,5.422,503.48,3.198h16.958l41.422,97.3H544.902z"
        />
        <path
          fill="currentColor"
          d="M637.754,100.498l-25.576-41.005h-20.989v41.005H575.76V44.203h48.789h0.139
			c3.521,0,6.533-1.251,9.035-3.753s3.753-5.512,3.753-9.035c0-3.521-1.251-6.533-3.753-9.035s-5.515-3.753-9.035-3.753H575.76
			V3.198h48.789c7.506,0,13.97,2.595,19.391,7.784c5.421,5.191,8.361,11.492,8.826,18.904v0.139
			c0.278,6.95-1.737,13.184-6.047,18.696c-4.309,5.514-9.893,8.965-16.749,10.355l25.854,41.422H637.754z"
        />
        <path
          fill="currentColor"
          d="M670.419,77.494c-4.541-7.83-6.811-16.379-6.811-25.646c0-9.265,2.27-17.813,6.811-25.645
			c4.539-7.83,10.749-14.039,18.626-18.626c7.876-4.587,16.448-6.88,25.715-6.88c5.189,0,10.238,0.788,15.151,2.363
			c4.911,1.577,9.404,3.753,13.483,6.533c4.076,2.78,7.69,6.116,10.842,10.008l-11.259,11.259
			c-3.245-4.448-7.346-7.944-12.302-10.494c-4.958-2.548-10.265-3.823-15.916-3.823c-9.729,0-18.048,3.453-24.95,10.355
			c-6.904,6.904-10.355,15.221-10.355,24.95c0,9.73,3.451,18.049,10.355,24.951c6.902,6.904,15.221,10.355,24.95,10.355
			c5.651,0,10.958-1.273,15.916-3.822c4.956-2.548,9.057-6.047,12.302-10.495l11.259,11.259
			c-4.727,5.838-10.542,10.449-17.444,13.831c-6.904,3.382-14.248,5.073-22.032,5.073c-9.267,0-17.839-2.293-25.715-6.881
			C681.168,91.532,674.958,85.325,670.419,77.494z"
        />
        <path
          fill="currentColor"
          d="M832.216,59.493h-48.65v41.005h-15.429V44.203h25.298v-0.139c19.46,0.093,32.387,0.139,38.781,0.139V3.198
			h15.29v97.3h-15.29V59.493z M768.137,28.774V3.198h15.429v25.576H768.137z"
        />
        <path
          fill="currentColor"
          d="M876.833,100.498h-15.429v-97.3h15.429V100.498z"
        />
        <path
          fill="currentColor"
          d="M970.102,18.627h-79.369V3.198h79.369V18.627z M938.131,100.498h-15.429V34.056h15.429V100.498z"
        />
        <path
          fill="currentColor"
          d="M984,3.198h75.06v15.429H984V3.198z M1059.06,85.068v15.43H984V44.203h75.06v15.29h-59.631v25.575H1059.06
			z"
        />
        <path
          fill="currentColor"
          d="M1079.771,77.494c-4.541-7.83-6.811-16.379-6.811-25.646c0-9.265,2.27-17.813,6.811-25.645
			c4.539-7.83,10.749-14.039,18.626-18.626c7.876-4.587,16.448-6.88,25.715-6.88c5.189,0,10.238,0.788,15.151,2.363
			c4.911,1.577,9.404,3.753,13.483,6.533c4.076,2.78,7.69,6.116,10.842,10.008l-11.259,11.259
			c-3.245-4.448-7.346-7.944-12.302-10.494c-4.958-2.548-10.265-3.823-15.916-3.823c-9.729,0-18.048,3.453-24.95,10.355
			c-6.904,6.904-10.355,15.221-10.355,24.95c0,9.73,3.451,18.049,10.355,24.951c6.902,6.904,15.221,10.355,24.95,10.355
			c5.651,0,10.958-1.273,15.916-3.822c4.956-2.548,9.057-6.047,12.302-10.495l11.259,11.259
			c-4.727,5.838-10.542,10.449-17.444,13.831c-6.904,3.382-14.248,5.073-22.032,5.073c-9.267,0-17.839-2.293-25.715-6.881
			C1090.52,91.532,1084.31,85.325,1079.771,77.494z"
        />
        <path
          fill="currentColor"
          d="M1256.857,18.627h-79.369V3.198h79.369V18.627z M1224.887,100.498h-15.429V34.056h15.429V100.498z"
        />
      </g>
    </g>
  </svg>
</template>