<template>
  <td
    class="first:rounded-l-full last:rounded-r-full  active:outline-none"
    :class="[
      stickyOnScrollClasses,
      alignmentClass, 
      textless ? '' : 'px-1 md:px-4 py-1 font-sm truncate',
      extendClasses,
    ]"
  >
    <slot />
  </td>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { Alignment } from '@/types/base'


export default defineComponent({
  props: {
    align: {
      type: String as PropType<Alignment>,
      validator: (val: Alignment) => ['left', 'center', 'right'].includes(val),
      default: 'left',
    },
    textless: {
      type: Boolean,
      default: false,
    },
    stickyOnScroll: {
      type: Boolean,
      default: false,
    },
    extendClasses: {
      type: String,
      default: '',
    },
    selectedRow: {
      type: Boolean,
      required: true,
    },
  },

  setup (props) {
    const alignmentClass = computed(() => {
      if (props.align === 'center') {
        return 'text-center'
      }
      if (props.align === 'right') {
        return 'text-center'
      }
      return 'text-left'
    })

    const stickyOnScrollClasses = computed(() => {
      if (!props.stickyOnScroll) return ''

      const themeClasses = props.selectedRow ? 'bg-blue-medium text-white' : 'bg-gray-100'

      return `sticky left-0 bg-opacity-50 ${themeClasses}`
    })

    return {
      alignmentClass,
      stickyOnScrollClasses,
    }
  },
})
</script>
