<template>
  <SelectInput
    :label="label"
    :loading-options="loadingOptions"
    :options="options"
    :model-value="inputValue"
    :valid="meta.valid"
    :name="name"
    v-bind="{ ...$attrs }"
    @update:model-value="handleChange"
    @blur="handleBlur"
  >
    <template #message>
      <div :class="[errorMessage ? 'mb-1' : 'mb-3']">
        <p
          v-if="errorMessage"
          class="text-xs text-red-medium"
        >
          {{ errorMessage }}
        </p>
      </div>
    </template>
  </SelectInput>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { SelectOption } from '@/types/form'
import { useField } from 'vee-validate'

export default defineComponent({

  props: {
     loadingOptions: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    name: { // allows browser autocompletion
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },

  setup (props) {
    const { 
      value: inputValue, 
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        initialValue: props.value,
      },
    ) 

    return {
      handleBlur,
      handleChange,
      errorMessage,
      inputValue,
      meta,
    }
  },
})
</script>

<style scoped>

</style>