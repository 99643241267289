import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// Store
import store from '@/store'
import { GetterTypes as AuthGetters } from '@/store/auth/getters'
import { ActionTypes as AuthActions } from '@/store/auth/actions'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import { ActionTypes as ToastActions } from '@/store/toast'

// Components
import Home from '../views/Home.vue'
import Login from '../views/auth/Login.vue'

// Types
import { AuthRouteGuard } from '@/types/auth'
import { ToastNotification } from '@/types/toast'


const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'dashboard',
		component: Home,
		meta: {
			authenticated: true,
		} as AuthRouteGuard,
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: {
			authenticated: false,
		} as AuthRouteGuard,
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/auth/Register.vue'),
		meta: {
			authenticated: false,
		} as AuthRouteGuard,
	},
	{
		path: '/logout',
		name: 'logout',
		component: () => import('../views/auth/Logout.vue'),
		meta: {
			authenticated: true,
		} as AuthRouteGuard,
	},
	{
		path: '/forgot',
		name: 'forgot-password',
		component: () => import('../views/auth/Forgot.vue'),
		meta: {
			authenticated: false,
		} as AuthRouteGuard,
	},
	{
		path: '/reset',
		name: 'reset-password',
		component: () => import('../views/auth/Reset.vue'),
		meta: {
			authenticated: false,
		} as AuthRouteGuard,
	},
	{
		path: '/profile',
		name: 'my-profile',
		meta: {
			authenticated: true,
		} as AuthRouteGuard,
		component: () => import('../views/Profile.vue'),
	},
	{
		path: '/personnel',
		name: 'personnel',
		meta: {
			authenticated: true,
			roles: ['admin', 'super'],
		} as AuthRouteGuard,
		component: () => import('../views/Personnel.vue'),
	},
	{
		path: '/staff/create',
		name: 'create-staff',
		meta: {
			authenticated: true,
			roles: ['admin', 'super'],
		} as AuthRouteGuard,
		component: () => import('../views/StaffCreate.vue'),
	},
	{
		path: '/staff/:userId',
		name: 'view-staff',
		meta: {
			authenticated: true,
			roles: ['admin', 'super'],
		} as AuthRouteGuard,
		component: () => import('../views/StaffView.vue'),
		props: true, // pass route.params as props
	},
	{
		path: '/staff/:userId/edit',
		name: 'edit-staff',
		meta: {
			authenticated: true,
		} as AuthRouteGuard,
		component: () => import('../views/StaffEdit.vue'),
		props: true, // pass route.params as props
	},
	{
		path: '/master-rota',
		name: 'master-rota',
		meta: {
			authenticated: true,
			roles: ['admin', 'super'],
		} as AuthRouteGuard,
		component: () => import('../views/MasterRota.vue'),
	},
	{
		path: '/daily-rota',
		name: 'daily-rota',
		meta: {
			authenticated: true,
		} as AuthRouteGuard,
		component: () => import('../views/DailyRota.vue'),
	},
	{
		path: '/requests',
		name: 'requests',
		meta: {
			authenticated: true,
			roles: ['admin', 'super'],
		} as AuthRouteGuard,
		component: () => import('../views/Requests.vue'),
	},
	{
		path: '/:notFound(.*)',
		redirect: '/', // could create a 404 component here instead.
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	linkActiveClass: 'text-red-500',
	linkExactActiveClass: 'text-red-600',
	scrollBehavior(_to, _from, savedPosition) {
		// if 'back' is pressed, return to previous scoll position
		if (savedPosition) return savedPosition
		// else scroll to top of page
		return {
			left: 0,
			top: 0,
		}
	},
})

router.beforeEach((to, from, next) => {
	if (!store.getters[AuthGetters.ATTEMPTED_AUTO_LOGIN]) {
		// Attempt an auto-login
		store.dispatch(AuthActions.ATTEMPT_AUTO_LOGIN_START, to.fullPath)
		// no navigation til autologin completes.
		next(false)
		return
	}

	// if route requires auth, but user is not logged in
	if (to.meta.authenticated && !store.getters[AuthGetters.IS_AUTH]) {
		next({ name: 'login' })
	} else 
	// if route is for non-auth'd users only, but the user is logged in
	if (!to.meta.authenticated && store.getters[AuthGetters.IS_AUTH]) {
		next({ name: 'dashboard' })
	}  else
	// if route is for specified roles only, but the user does not have a required role
	if (to.meta?.roles?.length && !to.meta.roles.includes(store.getters[UserGetters.ROLE])) {
		store.dispatch(ToastActions.SHOW, {
			type: 'warning',
			heading: 'Unauthorized',
			content: 'You do not have permission to perform this action.',
		} as ToastNotification)
		// route back to current page (this is '/' if on initial load regardless of url
		// e.g. /personnel should be from '/')
		next(from.fullPath)
	}
	else {
		next()
	}
})


// runs once a navigation has been confirmed.
// could be used for analytics
// router.afterEach((to, from) => {
// 	console.log('Global afterEach')
// 	console.log(to, from)
// })

export default router
