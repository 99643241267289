import { Creatable, Updateable, Preferable } from '@/types/users'
import api from './'

export default {
  getSelf() {
    return api.get('/user')
  },

  show(userId: string) {
    return api.get(`/users/${userId}`)
  },

  all(queryString: string) {
    return api.get(`/users${queryString}`)
  },

  create(payload: Creatable) {
    return api.post('/users', payload)
  },

  update(userId: string, payload: Updateable) {
    return api.put(`/users/${userId}`, payload)
  },

  updateRole(userId: number, payload: { role: string }) {
    return api.post(`/users/${userId}/role`, payload)
  },

  updateSlotPreferences(userId: string, payload: Preferable) {
    return api.post(`/users/${userId}/preferences`, payload)
  },

  getSlotPreferenceOptions() {
    return api.get('/preferences/day_slots')
  },

  delete(userId: string) {
    return api.delete(`/users/${userId}`)
  },

  inviteUser(userId: string) {
    return api.post(`/users/${userId}/invite`)
  },

  roster(userId: string, startDate: string, endDate: string, withGhost = 0) {
    return api.get(`/users/${userId}/roster?start_date=${startDate}&end_date=${endDate}&full=${withGhost}`)
  },
}