<template>
  <div class="bg-gray-200 max-h-screen min-h-screen w-full pl-8 xs:pl-12 md:pl-20 flex flex-col">
    <TheHeader />
    <RouterView v-slot="{ Component }">
      <transition
        name="fade"
        mode="out-in"
      >
        <component :is="Component" />
      </transition>
    </RouterView>
    <div class="fixed top-0 left-0 h-screen z-40">
      <TheSideDrawer />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TheHeader from '@/components/singletons/TheHeader.vue'
import TheSideDrawer from '@/components/singletons/TheSideDrawer.vue'

export default defineComponent({
  components: {
    TheHeader,
    TheSideDrawer,
  },
})
</script>
