<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-auto"
    viewBox="0 0 120.822 11.307"
    enable-background="new 0 0 120.822 11.307"
    xml:space="preserve"
  >
    <g enable-background="new">
      <path
        fill="currentColor"
        d="M3.712,0.106l2.88,6.576l2.88-6.576h2.896v11.2h-1.776V1.963l-4,9.151L2.576,1.93H0.8V0.106H3.712z M2.576,11.306H0.8V4.875h1.776V11.306z"
      />
      <path
        fill="currentColor"
        d="M23.504,11.306l-1.152-2.688h-5.296l-1.136,2.688h-1.952l2.656-6.24h1.952L17.84,6.795h3.744
		c-1.792-4.203-2.742-6.432-2.848-6.688h1.952l4.768,11.2H23.504z"
      />
      <path
        fill="currentColor"
        d="M35.152,1.131L34,2.507c-0.864-0.715-1.782-1.04-2.752-0.977c-0.342,0.032-0.651,0.112-0.928,0.24
		c-0.256,0.117-0.469,0.277-0.64,0.48c-0.245,0.299-0.357,0.661-0.336,1.088c0.032,0.171,0.109,0.33,0.232,0.479
		s0.25,0.272,0.384,0.368c0.133,0.096,0.328,0.195,0.584,0.296c0.256,0.102,0.466,0.176,0.632,0.225
		c0.166,0.048,0.408,0.119,0.728,0.216c0.522,0.149,0.92,0.27,1.192,0.359c0.272,0.091,0.611,0.24,1.016,0.448
		s0.704,0.43,0.896,0.664s0.352,0.547,0.48,0.937c0.128,0.389,0.17,0.84,0.128,1.352v0.016c-0.075,0.565-0.288,1.072-0.64,1.521
		c-0.427,0.522-1.003,0.901-1.728,1.136c-0.694,0.214-1.44,0.267-2.24,0.16c-1.387-0.171-2.704-0.757-3.952-1.76l1.12-1.393
		c0.981,0.779,1.995,1.232,3.04,1.36c0.555,0.075,1.051,0.043,1.488-0.096c0.384-0.117,0.672-0.299,0.864-0.544
		c0.139-0.171,0.224-0.368,0.256-0.593c0.011-0.213-0.029-0.407-0.12-0.584c-0.091-0.176-0.198-0.319-0.32-0.432
		s-0.314-0.224-0.576-0.336c-0.261-0.112-0.477-0.197-0.648-0.256c-0.17-0.059-0.424-0.136-0.76-0.232
		c-0.336-0.096-0.589-0.17-0.76-0.224c-0.17-0.054-0.408-0.136-0.712-0.248c-0.304-0.112-0.539-0.216-0.704-0.313
		c-0.165-0.096-0.36-0.224-0.584-0.384c-0.224-0.16-0.397-0.328-0.52-0.504s-0.237-0.389-0.344-0.64
		c-0.107-0.251-0.182-0.521-0.224-0.809V3.467c-0.064-0.906,0.187-1.701,0.752-2.384c0.331-0.395,0.747-0.709,1.248-0.944
		c0.491-0.224,1.013-0.357,1.568-0.4c0.438-0.032,0.88-0.005,1.328,0.08c0.448,0.085,0.907,0.237,1.376,0.456
		C34.293,0.493,34.736,0.779,35.152,1.131z"
      />
      <path
        fill="currentColor"
        d="M46.367,1.883h-9.136V0.106h9.136V1.883z M42.687,11.306h-1.776V3.658h1.776V11.306z"
      />
      <path
        fill="currentColor"
        d="M47.967,0.106h8.64v1.776h-8.64V0.106z M56.607,9.53v1.776h-8.64v-6.48h8.64v1.761h-6.864V9.53H56.607z"
      />
      <path
        fill="currentColor"
        d="M65.343,11.306l-2.943-4.72h-2.416v4.72h-1.776v-6.48h5.616h0.016c0.406,0,0.752-0.144,1.041-0.432
		c0.287-0.288,0.432-0.635,0.432-1.04s-0.145-0.752-0.432-1.04c-0.289-0.288-0.635-0.432-1.041-0.432h-5.632V0.106h5.616
		c0.863,0,1.607,0.299,2.232,0.896c0.623,0.598,0.962,1.323,1.016,2.176v0.016c0.031,0.801-0.2,1.518-0.696,2.152
		c-0.495,0.635-1.139,1.032-1.928,1.192l2.976,4.768H65.343z"
      />
      <path
        fill="currentColor"
        d="M81.712,11.306l-2.944-4.72h-2.416v4.72h-1.776v-6.48h5.616h0.017c0.404,0,0.752-0.144,1.039-0.432
		c0.288-0.288,0.432-0.635,0.432-1.04s-0.144-0.752-0.432-1.04c-0.287-0.288-0.635-0.432-1.039-0.432h-5.633V0.106h5.616
		c0.864,0,1.608,0.299,2.231,0.896c0.625,0.598,0.963,1.323,1.017,2.176v0.016c0.032,0.801-0.2,1.518-0.696,2.152
		s-1.139,1.032-1.928,1.192l2.977,4.768H81.712z"
      />
      <path
        fill="currentColor"
        d="M90.783-0.181c0.8,0,1.563,0.155,2.288,0.464c0.725,0.31,1.352,0.728,1.88,1.256
		c0.528,0.527,0.946,1.154,1.256,1.88s0.464,1.487,0.464,2.288c0,1.066-0.264,2.051-0.791,2.951
		c-0.529,0.902-1.244,1.616-2.145,2.145c-0.902,0.528-1.886,0.792-2.952,0.792c-1.067,0-2.054-0.264-2.96-0.792
		s-1.621-1.242-2.145-2.145c-0.522-0.9-0.783-1.885-0.783-2.951s0.261-2.051,0.783-2.952c0.523-0.901,1.238-1.616,2.145-2.144
		C88.729,0.082,89.716-0.181,90.783-0.181z M93.647,8.57c0.789-0.789,1.184-1.744,1.184-2.863c0-1.12-0.395-2.075-1.184-2.864
		s-1.744-1.185-2.864-1.185s-2.075,0.396-2.864,1.185s-1.184,1.744-1.184,2.864c0,1.119,0.395,2.074,1.184,2.863
		c0.789,0.79,1.744,1.185,2.864,1.185S92.858,9.36,93.647,8.57z"
      />
      <path
        fill="currentColor"
        d="M107.407,1.883H98.27V0.106h9.137V1.883z M103.727,11.306h-1.776V3.658h1.776V11.306z"
      />
      <path
        fill="currentColor"
        d="M118.543,11.306l-1.151-2.688h-5.297l-1.136,2.688h-1.952l2.656-6.24h1.952l-0.736,1.729h3.744
		c-1.792-4.203-2.741-6.432-2.849-6.688h1.953l4.768,11.2H118.543z"
      />
    </g>
  </svg>
</template>