import { ref, Ref, watch } from 'vue'
import UsersAPI from '@/apis/rota-architect/users'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { Entry, RosterDate } from '@/types/roster'

export default function useSingleUserRota(
  userId: Ref<string>, 
  startDate: Ref<string>, 
  endDate: Ref<string>,
  withGhost = 0,
) {

  const dispatch = useToasts()

  // fetch roster info.
  const rota = ref<RosterDate<Entry>[]>([])
  const loadingRota = ref(false)

  const fetchRoster = () => {
    if (loadingRota.value) return

    loadingRota.value = true

    UsersAPI.roster(userId.value, startDate.value, endDate.value, withGhost)
      .then((res) => {
        rota.value = res.data
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loadingRota.value = false
      })
  }

  watch(
    [startDate, endDate],
    () => {
      fetchRoster()
    },
    {
      immediate: true,
    },
  )

  // for updating rota outside of hook
  const updateRoster = (date_id: number, newEntries: Entry[]) => {
    if (loadingRota.value) return

    const updatingIndex = rota.value.findIndex(item => item.id === date_id)
    rota.value[updatingIndex].entries = newEntries
  }

  return {
    loadingRota,
    rota,
    fetchRoster,
    updateRoster,
  }
}