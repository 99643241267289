// Imported types
import { State } from './'
import { ErrorMap } from '@/types/ajax'
import { UserSuccessResponse } from '@/types/users'

/**
 * Mutation Types
 */
export enum _MutationTypes {
  SET_USER_INIT = 'SET_USER_INIT',
  SET_USER_DATA = 'SET_USER_DATA',
  SET_USER_ERROR = 'SET_USER_ERROR',
  CLEAR_USER_DATA = 'CLEAR_USER_DATA',
}

export default {
  [_MutationTypes.SET_USER_INIT](state: State) {
    state.requesting = true
  },

  [_MutationTypes.SET_USER_DATA](state: State, payload: UserSuccessResponse) {
    state.user = payload
    state.requesting = false
    state.error = null
  },

  [_MutationTypes.CLEAR_USER_DATA](state: State) {
    state.user = null
    state.requesting = false
    state.error = null
  },

  [_MutationTypes.SET_USER_ERROR](state: State, payload: ErrorMap) {
    state.user = null
    state.requesting = false
    state.error = payload
  },
}