
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'

// Types
import { UserBasics } from '@/types/users'

// Components
import HomeDashboard from '@/components/home/layouts/HomeDashboard.vue'
import UserRota from '@/components/rota/user/UserRota.vue'

export default defineComponent({
  components: {
    HomeDashboard,
    UserRota,
  },

  setup () {
    const store = useStore()

    const user = computed(() => store.getters[UserGetters.USER] as UserBasics)

    const welcomeMessage = computed(() => {
      const hour = new Date().getHours()
      const time = hour < 12 ? 'Morning' : hour <= 17 ? 'Afternoon' : 'Evening'

      return `Good ${time} ${user.value.first_name}!`
    })
    
    return {
      welcomeMessage,
      user,
    }
  },
})
