<template>
  <th
    :class="[
      stickyOnScrollClasses,
      alignmentClass,
      textlessClass,
    ]"
  >
    <template v-if="sortable">
      <button
        type="button"
        class="font-medium"
        @click="emitSortEvent"
      >
        <slot />
        <template v-if="sort[0] === '-' && isActive">
          <svg
            class="ml-1 inline-block h-4"
            :class="isActive ? 'text-gray-600' : 'text-gray-400'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
            />
          </svg>
        </template>

        <template v-else>
          <svg
            class="ml-1 inline-block h-4"
            :class="isActive ? 'text-gray-600' : 'text-gray-400'"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 4h13M3 8h9m-9 4h9m5-4v12m0 0l-4-4m4 4l4-4"
            />
          </svg>
        </template>
      </button>
    </template>
    <template v-else>
      <slot />
    </template>
  </th>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { Alignment } from '@/types/base'


export default defineComponent({
  props: {
    align: {
      type: String as PropType<Alignment>,
      validator: (val: Alignment) => ['left', 'center', 'right'].includes(val),
      default: 'left',
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    textless: {
      type: Boolean,
      default: false,
    },
    stickyOnScroll: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    sort: {
      type: String,
      default: '',
    },
  },
  
  emits: ['sort'],
  
  setup (props, ctx) {
    const alignmentClass = computed(() => {
      if (props.align === 'center') {
        return 'text-center'
      }
      if (props.align === 'right') {
        return 'text-center'
      }
      return 'text-left'
    })

    const textlessClass = computed(() => props.textless ? '' : 'px-4 py-4 tracking-wide font-medium')

    const sortColumnName = computed(() => props.sort.replace(/^-/, ''))

    const isActive = computed(() => sortColumnName.value === props.name)

    const emitSortEvent = () => {
      ctx.emit('sort', (isActive.value && props.sort[0] !== '-' ? '-' : '') + props.name)
    }

    const stickyOnScrollClasses = computed(() => {
      if (!props.stickyOnScroll) return ''

      return 'sticky left-0 bg-grey bg-opacity-75'
    })

    return {
      alignmentClass,
      textlessClass,
      isActive,
      emitSortEvent,
      stickyOnScrollClasses,
    }
  },
})
</script>
