import { State } from './'

/**
 * Getter Types
 */
export enum _GetterTypes {
  ERROR = 'ERROR',
  REQUESTING = 'REQUESTING',
  USER_ID = 'USER_ID',
  USER = 'USER',
  ROLE = 'ROLE',
}

// PUBLIC
export enum GetterTypes {
  ERROR = 'user/ERROR',
  REQUESTING = 'user/REQUESTING',
  USER_ID = 'user/USER_ID',
  USER = 'user/USER',
  ROLE = 'user/ROLE',
}

export default {
  [_GetterTypes.ERROR]: (state: State) => state.error,

  [_GetterTypes.REQUESTING]: (state: State) => state.requesting,

  [_GetterTypes.USER_ID]: (state: State) => state.user?.id,

  [_GetterTypes.USER]: (state: State) => state.user,

  [_GetterTypes.ROLE]: (state: State) => state.user?.role,
}