<template>
  <div
    class="text-white bg-red-medium p-3 rounded-lg flex items-center shadow-sm"
    :class="[extendWrapperClasses]"
  >
    <span class="w-12 h-12 mr-3">
      <WarningIcon />
    </span>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import WarningIcon from '@/components/icons/WarningIcon.vue'

export default defineComponent({
  components: {
    WarningIcon,
  },

  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },
})
</script>
