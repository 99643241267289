
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },
})
