<template>
  <h1 
    class="text-blue-medium font-bold pl-1 md:pl-3 leading-none text-xl md:text-3xl my-3"
    :class="extendWrapperClasses"
  >
    <slot />
  </h1>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    extendWrapperClasses: {
     type: String,
     default: '',
    },
  },
})
</script>
