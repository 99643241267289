
import { SizeMap, StringMap } from '@/types/base'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: String,
      validator: (val: string) => ['sm', 'md', 'full'].includes(val),
      default: 'full',
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: 'text-blue-medium hover:text-blue-dark',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  setup (props) {
    const iconSizeClasses: StringMap & SizeMap= {
      sm: 'h-4 w-4',
      md: 'h-5 w-5',
      full: 'h-6 w-6',
      xl: 'h-8 w-8',
    }

    const colorClasses = computed(() => props.disabled ? 'text-gray-300' : props.color)

    return {
      baseClasses: 'flex items-center justify-center transition duration-150 focus:outline-none',
      sizeClasses: iconSizeClasses[props.size],
      roundedClasses: props.rounded ? 'rounded-full' : '',
      colorClasses,
    }
  },
})
