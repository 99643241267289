
import { defineComponent, computed, watch } from 'vue'
import { useRouter } from 'vue-router'

// Store
import { useStore } from 'vuex'
import { GetterTypes as AuthGetters } from '@/store/auth/getters'

// Components
import TheBaseRoutesLayout from '@/components/singletons/layouts/TheBaseRoutesLayout.vue'
import TheAuthRoutesLayout from '@/components/singletons/layouts/TheAuthRoutesLayout.vue'
import TheToastNotifications from '@/components/singletons/TheToastNotifications.vue'

export default defineComponent({
  components: {
    TheBaseRoutesLayout,
    TheAuthRoutesLayout,
    TheToastNotifications,
  },

  setup () {
    const store = useStore()
    const router = useRouter()

    const isAuth = computed(() => store.getters[AuthGetters.IS_AUTH])

    const attemptedAutoLogin = computed(() => store.getters[AuthGetters.ATTEMPTED_AUTO_LOGIN])

    // Start routing on auto-attempt complete
    watch(
      attemptedAutoLogin,
      (current: boolean, prev: boolean) => {
        if (current && !prev) {
          router.push(store.getters[AuthGetters.INITIAL_PATH])
        }
      },
    )

    return {
      isAuth,
      attemptedAutoLogin,
    }
  },
})
