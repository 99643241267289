
import { computed, defineComponent } from 'vue'

// Store
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import { 
  GetterTypes as BaseGetters,
  ActionTypes as BaseActions,
} from '@/store/base'

// Utils
import capitalizeFirstLetter from '@/utils/capitalizeFirstLetter'

// Icons
import HeaderProfileIcon from '@/components/icons/HeaderProfileIcon.vue'
import ProfileIconThick from '@/components/icons/ProfileIconThick.vue'
import LogoutIconThick from '@/components/icons/LogoutIconThick.vue'

export default defineComponent({
  components: {
    HeaderProfileIcon,
    ProfileIconThick,
    LogoutIconThick,
  },

  emits: ['clicked'],

  setup () {
    const store = useStore()

    const user = computed(() => store.getters[UserGetters.USER])

    const userMenuOpen = computed(() => store.getters[BaseGetters.USER_MENU_OPEN])

    const closeMenu = () => {
      store.dispatch(BaseActions.HIDE_USER_MENU)
    }

    return {
      userId: user.value.id,
      userName: user.value.name,
      userRole: capitalizeFirstLetter(user.value.role),
      userMenuOpen,
      closeMenu,
    }
  },
})
