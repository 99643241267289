
import { defineComponent, computed } from 'vue'
import { StringMap, SizeMap } from '@/types/base'

export default defineComponent({
  props: {
    theme: {
      type: String,
      validator: (val: string) => ['auth', 'base', 'success', 'delete'].includes(val),
      default: 'auth',
    },
    size: {
      type: String,
      validator: (val: string) => ['xs', 'sm', 'md', 'full'].includes(val),
      default: 'full',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    working: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      validator: (val: string) => ['button', 'submit'].includes(val),
      default: 'button',
    },
  },

  emits: ['click'],

  setup (props, ctx) {
    const hasIcon = computed(() => !!ctx.slots.icon)

    const themeClasses: StringMap = {
      auth: 'bg-grey hover:bg-gray-900 xl:text-lg text-white',
      base: 'bg-blue-medium hover:bg-blue-dark text-sm xl:text-base text-white',
      success: 'bg-green-medium hover:bg-green-dark text-sm xl:text-base text-white',
      delete: 'bg-red-medium hover:bg-red-dark text-sm xl:text-base text-white',
    }

    const sizeClasses: StringMap & SizeMap = {
      xs: 'min-w-24 w-auto h-6 text-xs px-2',
      sm: 'w-32 py-1 h-8 px-2',
      md: 'w-auto px-4 py-2 h-10',
      full: 'w-full py-3 h-12',
    }

    const iconSizeClasses: StringMap & SizeMap= {
      xs: 'h-3 w-3',
      sm: 'h-4 w-4',
      md: 'h-5 w-5',
      full: 'h-6 w-6',
    }
 
    return {
      baseClasses: 'active:outline-none focus:outline-none rounded-lg inline-flex items-center justify-center',
      themeClasses: themeClasses[props.theme],
      sizeClasses: sizeClasses[props.size],
      hasIcon,
      iconBaseClasses: 'flex mr-2',
      iconSizeClasses: iconSizeClasses[props.size],
    }
  },
})
