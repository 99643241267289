import { Module } from 'vuex'

import { State as RootState } from '@/store'

/**
 * State Type
 */
export type State = { 
	sideDrawerOpen: boolean;
	userMenuOpen: boolean;
}

/**
 * Mutation Types
 */
enum _MutationTypes {
	SET_SIDE_DRAWER_OPEN = 'SET_SIDE_DRAWER_OPEN',
	SET_USER_MENU_OPEN = 'SET_USER_MENU_OPEN',
}

/**
 * Action Types
 */
enum _ActionTypes {
	SHOW_SIDE_DRAWER = 'SHOW_SIDE_DRAWER',
	HIDE_SIDE_DRAWER = 'HIDE_SIDE_DRAWER',
	SHOW_USER_MENU = 'SHOW_USER_MENU',
	HIDE_USER_MENU = 'HIDE_USER_MENU',
}

// PUBLIC
export enum ActionTypes {
	SHOW_SIDE_DRAWER = 'base/SHOW_SIDE_DRAWER',
	HIDE_SIDE_DRAWER = 'base/HIDE_SIDE_DRAWER',
	SHOW_USER_MENU = 'base/SHOW_USER_MENU',
	HIDE_USER_MENU = 'base/HIDE_USER_MENU',
}

/**
 * Getter Types
 */
enum _GetterTypes {
	SIDE_DRAWER_OPEN = 'SIDE_DRAWER_OPEN',
	USER_MENU_OPEN = 'USER_MENU_OPEN',
}

// PUBLIC
export enum GetterTypes {
	SIDE_DRAWER_OPEN = 'base/SIDE_DRAWER_OPEN',
	USER_MENU_OPEN = 'base/USER_MENU_OPEN',
}


export const baseModule: Module<State, RootState> = {
  namespaced: true,

	state: () =>  ({
		sideDrawerOpen: false,
		userMenuOpen: false,
  }),
  
	mutations: { // must be synchronous.
		[_MutationTypes.SET_SIDE_DRAWER_OPEN](state, payload: boolean) {
			state.sideDrawerOpen = payload
		},
		[_MutationTypes.SET_USER_MENU_OPEN](state, payload: boolean) {
			state.userMenuOpen = payload
		},
  },
  
	getters: {
		[_GetterTypes.SIDE_DRAWER_OPEN]: (state) =>  state.sideDrawerOpen,
		[_GetterTypes.USER_MENU_OPEN]: (state) =>  state.userMenuOpen,
  },
  
	// good practice to always put actions before a mutation
	actions: { // for async actions.
		[_ActionTypes.SHOW_SIDE_DRAWER](ctx) {
			ctx.commit(_MutationTypes.SET_SIDE_DRAWER_OPEN, true)
		},
		[_ActionTypes.HIDE_SIDE_DRAWER](ctx) {
			ctx.commit(_MutationTypes.SET_SIDE_DRAWER_OPEN, false)
		},
		[_ActionTypes.SHOW_USER_MENU](ctx) {
			ctx.commit(_MutationTypes.SET_USER_MENU_OPEN, true)
		},
		[_ActionTypes.HIDE_USER_MENU](ctx) {
			ctx.commit(_MutationTypes.SET_USER_MENU_OPEN, false)
		},
	},
}
