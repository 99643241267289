<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g>
      <path
        fill="currentColor"
        d="M217.75,172.5H42.25C19.468,172.5,1,190.968,1,213.75S19.468,255,42.25,255h175.5
		c22.781,0,41.25-18.468,41.25-41.25S240.531,172.5,217.75,172.5z"
      />
      <polygon
        fill="currentColor"
        points="27.147,165.734 129.058,111.617 232.852,166.734 255.012,125.005 179.43,84.868 255.012,44.731 
		232.851,3 129.058,58.119 27.148,4 4.987,45.731 78.687,84.868 4.987,124.006 	"
      />
    </g>
  </svg>
</template>