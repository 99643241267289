
import { defineComponent } from 'vue'
import TheHeader from '@/components/singletons/TheHeader.vue'
import TheSideDrawer from '@/components/singletons/TheSideDrawer.vue'

export default defineComponent({
  components: {
    TheHeader,
    TheSideDrawer,
  },
})
