<template>
  <nav 
    class="h-full bg-blue-medium shadow-lg pt-6 text-white duration-300 ease-in-out transition-width"
    :class="[sideDrawerOpen ? 'sidedrawer-extended-width' : 'w-8 xs:w-12 md:w-20']"
  >    
    <span class="flex items-center justify-center w-8 xs:w-12 md:w-16 h-12 md:h-16 mb-1 md:mx-2">
      <BaseButtonHamburger
        :is-active="sideDrawerOpen"
        @toggle="toggleSideDrawerOpen"
      />
    </span>
    <ul>
      <TheSideDrawerNavItem
        v-for="item in primaryNavItems"
        :key="item.routeName"
        :nav-item="item"
        :expanded="sideDrawerOpen"
        @close-drawer="toggleSideDrawerOpen"
      />
      <hr class="py-1 mt-2 border-grey">
      <TheSideDrawerNavItem
        v-for="item in secondaryNavItems"
        :key="item.routeName"
        :nav-item="item"
        :expanded="sideDrawerOpen"
        @close-drawer="toggleSideDrawerOpen"
      />
    </ul>
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import moment from 'moment'
import useRole from '@/hooks/useRole'
import { NavItem } from '@/types/base'

// Store
import { useStore } from 'vuex'
import { ActionTypes as BaseActions } from '@/store/base'
import { GetterTypes as BaseGetters } from '@/store/base'

// Components
import TheSideDrawerNavItem from '@/components/singletons/TheSideDrawerNavItem.vue'


export default defineComponent({
  components: {
    TheSideDrawerNavItem,
  },

  setup () {
    const store = useStore()
    const { isStaff, isDesk } = useRole()

    const sideDrawerOpen = computed(() => store.getters[BaseGetters.SIDE_DRAWER_OPEN])

    const toggleSideDrawerOpen = () => {
      store.dispatch(
        sideDrawerOpen.value 
          ? BaseActions.HIDE_SIDE_DRAWER 
          : BaseActions.SHOW_SIDE_DRAWER,
      )
    }

    return {
      primaryNavItems: [
        {
          label: 'Dashboard',
          icon: 'dashboard',
          routeName: 'dashboard',
          hidden: false,
        },
        {
          label: 'Personnel',
          icon: 'personnel',
          routeName: 'personnel',
          hidden: isStaff.value || isDesk.value,
        },
        {
          label: 'Master Rota',
          icon: 'master-rota',
          routeName: 'master-rota',
          hidden: isStaff.value  || isDesk.value,
        },
        {
          label: 'Daily Rota',
          icon: 'daily-rota',
          routeName: 'daily-rota',
          hidden: false,
          query: {
            date: moment().format('YYYY-MM-DD'),
          },
        },
        {
          label: 'Requests',
          icon: 'requests',
          routeName: 'requests',
          hidden: isStaff.value  || isDesk.value,
        },
      ] as NavItem[],
      secondaryNavItems: [
        {
          label: 'View Profile',
          icon: 'profile',
          routeName: 'my-profile',
          hidden: false,
        },
        {
          label: 'Log out',
          icon: 'logout',
          routeName: 'logout',
          hidden: false,
        },
      ] as NavItem[],
      sideDrawerOpen,
      toggleSideDrawerOpen,
    }
  },
})
</script>

<style scoped>
.sidedrawer-extended-width {
  width: 250px;
}

@media only screen and (min-width: 768px) {
  .sidedrawer-extended-width {
    width: 350px;
  }
}

.transition-width {
  transition-property: width;
}

.mb-custom {
  margin-bottom: 4px;
}
</style>