<template>
  <li
    v-if="!navItem.hidden"
    :key="navItem.routeName"
    class="xs:px-1 md:px-2 py-1"
  >
    <RouterLink
      :to="{ name: navItem.routeName, params: { ...navItem.params }, query: { ...navItem.query } }"
      v-slot="{ isActive }"
    >
      <div 
        class="flex items-center rounded-lg hover:bg-blue-dark transition ease-in-out duration-150 has-tooltip"
        :class="[isActive ? activeClasses : '']"
        @click="closeDrawer"
      >
        <span class="flex items-center justify-center w-10 md:w-16 h-10 md:h-16 p-1 xs:p-2 md:p-4">
          <component
            :is="`${navItem.icon}-icon`"
          />
        </span>
        <span
          v-if="expanded"
          class="w-3/4 italic text-xl md:text-xl ml-2 truncate"
        >
          {{ navItem.label }}
        </span>
      </div>
    </RouterLink>
  </li>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { NavItem } from '@/types/base'

// Icons
import DashboardIcon from '@/components/icons/DashboardIcon.vue'
import PersonnelIcon from '@/components/icons/PersonnelIcon.vue'
import MasterRotaIcon from '@/components/icons/MasterRotaIcon.vue'
import DailyRotaIcon from '@/components/icons/DailyRotaIcon.vue'
import RequestsIcon from '@/components/icons/RequestsIcon.vue'
import ProfileIcon from '@/components/icons/ProfileIcon.vue'
import LogoutIcon from '@/components/icons/LogoutIcon.vue'


export default defineComponent({
  components: {
    DashboardIcon,
    PersonnelIcon,
    MasterRotaIcon,
    DailyRotaIcon,
    RequestsIcon,
    ProfileIcon,
    LogoutIcon,
  },

  props: {
    navItem: {
      type: Object as PropType<NavItem>,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-drawer'],
  
  setup (props, ctx) {
    const closeDrawer = () => {
      if (props.expanded) {
        ctx.emit('close-drawer')
      }
    }

    return {
      activeClasses: 'bg-blue-dark',
      closeDrawer,
    }
  },
})
</script>
