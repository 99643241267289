<template>
  <div
    v-if="!!$slots.header"
    class="my-2"
  >
    <slot name="header" />
  </div>
  <div class="grid-container min-h-70vh">
    <section class="item-rota">
      <slot name="rota" />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    

    return {}
  },
})
</script>

<style lang="scss" scoped>
.item-rota {
  grid-area: rota;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "rota";

  @screen lg {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
    grid-template-areas:
      "rota ."
      "rota ."
      "rota .";
  }

  @screen xl {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "rota . ."
      "rota . ."
      "rota . .";
  }
}
</style>