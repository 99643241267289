<template>
  <button
    v-if="clickable"
    class="border-white text-center flex items-center justify-center hover:bg-opacity-75 active:outline-none focus:outline-none relative transition-colors ease-in duration-500"
    :class="[
      themeClasses, 
      sizeClasses, 
      statusClasses, 
      tooltip ? 'has-tooltip' : '',
      isWithAbsence ? 'border-entry-absence' : 'border-white',
    ]"
    @click="$emit('clicked')"
  >
    <span :class="[size === 'multi' ? 'truncate w-32' : '']">
      {{ content }}
    </span>
    <transition name="fade-slow">
      <span
        v-if="isDeleting"
        class="absolute left-2 top-1/2 transform -translate-y-1/2 flex w-3 h-3 text-white"
      >
        <DeletingStatusIcon />
      </span>
      <span
        v-else-if="isDraft"
        class="absolute left-2 top-1/2 transform -translate-y-1/2 flex w-3 h-3 text-white"
      >
        <DraftStatusIcon />
      </span>
    </transition>
    <span
      v-if="isLocum"
      class="absolute right-2 top-1/2 transform -translate-y-1/2 flex w-3 h-3 text-white"
    >
      <LocumIcon />
    </span>
    <BaseToolTip
      v-if="tooltip"
      size="md"
    >
      {{ content }}

      <template v-if="withAbsence">
        <br>
        <span>(with {{ withAbsence }})</span>
      </template>
    </BaseToolTip>
  </button>

  <div
    v-else
    class="border-white bg-white flex items-center text-center relative"
    :class="[
      sizeClasses, 
      statusClasses, 
      tooltip ? 'has-tooltip' : '',
      isWithAbsence ? 'border-entry-absence' : 'border-white',
    ]"
  >
    <div
      v-if="grade"
      class="rounded-l-full w-12 text-blue-medium flex-shrink-0 text-sm flex items-center justify-center"
      :class="heightClasses"
    >
      {{ gradeOutput }}
    </div>
    <span
      class="rounded-r-full flex items-center justify-center"
      :class="[
        size === 'multi' ? 'truncate w-32 mx-auto' : 'w-full',
        grade ? '' : 'rounded-l-full',
        themeClasses,
        heightClasses,
      ]"
    >
      {{ content }}
    </span>
    <span
      v-if="isLocum"
      class="absolute right-2 top-1/2 transform -translate-y-1/2 flex w-3 h-3 text-white"
    >
      <LocumIcon />
    </span>
    <BaseToolTip v-if="tooltip && withAbsence">
      <span>with {{ withAbsence }}</span>
    </BaseToolTip>
  </div>
</template>

<script lang="ts">
import { StringMap } from '@/types/base'
import { computed, defineComponent, PropType } from 'vue'
import { pillThemeMap, gradeMap } from '@/constants/entries'
import { EntryPillType, EntryStatus, Grade } from '@/types/roster'

import DeletingStatusIcon from '@/components/icons/rota/DeletingStatusIcon.vue'
import DraftStatusIcon from '@/components/icons/rota/DraftStatusIcon.vue'
import LocumIcon from '@/components/icons/rota/LocumIcon.vue'

export default defineComponent({
  components: {
    DeletingStatusIcon,
    DraftStatusIcon,
    LocumIcon,
  },

  props: {
    type: {
      type: String,
      validator: (val: EntryPillType) => [
          EntryPillType.EARLY,
          EntryPillType.MID,
          EntryPillType.LATE,
          EntryPillType.NIGHT_ONE,
          EntryPillType.NIGHT_TWO,
          EntryPillType.ON_CALL,
          EntryPillType.ABSENCE,
          EntryPillType.STUDY_LEAVE,
          EntryPillType.SICKNESS,
          EntryPillType.OFF,
        ].includes(val),
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    status: {
      type: String as PropType<EntryStatus>,
      validator: (val: EntryStatus) => [
          EntryStatus.DRAFT,
          EntryStatus.PENDING,
          EntryStatus.APPROVED,
          EntryStatus.DELETING,
        ].includes(val),
      default: EntryStatus.APPROVED,
    },
    grade: {
      type: Number as PropType<Grade>,
      default: 0,
    },
    size: {
      type: String,
      validator: (val: string) => ['sm', 'md', 'full', 'multi', 'multi-sm'].includes(val),
      default: 'md',
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: false,
    },
    isLocum: {
      type: Boolean,
      default: false,
    },
    isWithAbsence: {
      type: Boolean,
      default: false,
    },
    withAbsence: {
      type: String,
      default: '',
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['clicked'],

  setup (props) {
    const sizeMap: StringMap = {
      'sm': 'h-6 w-56 border rounded-full',
      'md': 'h-7 w-64 border-2 rounded-full text-sm',
      'full': 'h-8 w-full border-4 text-sm rounded-full',
      'multi': 'w-full first:rounded-l-full last:border-l-2 first:border-r-2 last:rounded-r-full px-6 text-xs',
      'multi-sm': 'w-1/8 first:rounded-l-full last:border-l-2 first:border-r-2 last:rounded-r-full text-xs',
    }

    const innerHeightMap: StringMap = {
      'sm': 'h-4',
      'md': 'h-6',
      'full': 'h-6',
    }

    const statusClasses = computed(() => props.status === EntryStatus.DRAFT ? 'bg-opacity-50' : '')

    const isDeleting = computed(() => props.status === EntryStatus.DELETING)

    const isDraft = computed(() => props.status === EntryStatus.DRAFT)

    const themeClasses = computed(() => pillThemeMap[props.type])

    const gradeOutput = computed(() => props.grade ? gradeMap[props.grade] : '')

    return {
      themeClasses,
      gradeOutput,
      sizeClasses: sizeMap[props.size],
      heightClasses: innerHeightMap[props.size],
      statusClasses,
      isDeleting,
      isDraft,
    }
  },
})
</script>
