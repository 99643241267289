<template>
  <BaseCard extend-wrapper-classes="mt-2">
    <BaseCardHeader>
      Your Rota
      <template #headingRight>
        <div class="flex items-center justify-center md:justify-start">
          <span class="absolute -top-7 right-2 md:inline-block z-10">
            <transition name="fade">
              <BaseButton
                v-if="showBackToToday"
                theme="success"
                size="xs"
                :disabled="loadingRota"
                @click="goToToday"
              >
                Back to today
              </BaseButton>
            </transition>
          </span>
          
          <span
            class="flex flex-wrap items-center justify-center sm:ml-2 p-1 md:p-2 xxl:p-1 rounded-md shadow-md"
          >
            <p class="text-white italic text-sm mr-2 hidden sm:block">
              From: 
            </p>
            <DateInput 
              :starting-year="startingYear"
              :number-of-years="numberOfYears"
              :disabled="loadingRota"
              v-model="dateInputValue"
            />
          </span>
        </div>
      </template>
    </BaseCardHeader>

    <DataTable
      v-if="isTableView"
      extend-wrapper-classes="min-h-60vh md:min-h-70vh max-h-60vh md:max-h-70vh overflow-y-auto pt-1 px-1 md:px-2 xl:px-4 pb-2 xl:pb-4"
      :columns="columns"
      :rows="rows"
      :loading="loadingRota"
      headless
    >
      <template #column-date="{ value, row }">
        <div class="text-center">
          <p class="text-sm -mb-1">
            {{ value }}
          </p>
          <p class="text-xs">
            {{ row.day }} <span v-if="row.isHoliday">(PH)</span>
          </p>
        </div>
      </template>

      <template #column-entries="{ value }">
        <EntryPill
          :content="value[0].textContent"
          :type="value[0].type"
          size="full"
        />
      </template>
    </DataTable>
  </BaseCard>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import moment from 'moment'

// Hooks
import { useRoute, useRouter } from 'vue-router'
import useToasts from '@/hooks/useToasts'
import useSingleUserRota from '@/hooks/useSingleUserRota'
import useSingleUserRotaTable from '@/hooks/useSingleUserRotaTable'
import usePeriodStartDate from '@/hooks/usePeriodStartDate'

// Components
import EntryPill from '@/components/rota/EntryPill.vue'

export default defineComponent({
  components: {
    EntryPill,
  },

  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { warningToast } = useToasts()

    const isTableView = ref(true) // as oppose to calendar view.

    const userId = computed(() => store.getters[UserGetters.USER_ID].toString())
    
    const startDateString = computed(() => {
      if (
        route.query?.start_date && 
        moment(route.query.start_date.toString()).isValid()
      ) {
        return route.query.start_date.toString()
      }
      return moment().format('YYYY-MM-DD')
    })

    const endDateString = computed(() => {
      return moment(startDateString.value).add(4, 'weeks').format('YYYY-MM-DD')
    })

    const { rota, loadingRota } = useSingleUserRota(userId, startDateString, endDateString)
    const { rows, columns } = useSingleUserRotaTable(rota, loadingRota)

    /**
     * Changing start date
     */
    const {
      startDate: inputMin,
    } = usePeriodStartDate()

    const dateInputValue = ref(startDateString.value)

    const startingYear = computed(() => moment(inputMin.value).year() ?? moment().year())
    const numberOfYears = computed(() => moment(inputMin.value).diff(moment(), 'years') + 1)

    const updateStartDate = (val: string) => {
      const newDate = moment(val)

      if (newDate.isBefore(inputMin.value)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`'From' date cannot be before ${moment(inputMin.value).format('DD MMM YYYY')}.`, 'Date Range')
        return
      }

      const max = moment().add(11, 'months')
      if (newDate.isAfter(max)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`'From' date cannot be after ${max.format('DD MMM YYYY')}.`, 'Date Range')
        return
      }

      router.replace({
        query: {
          start_date: val,
        },
      })
    }

    const showBackToToday = computed(() => startDateString.value !== moment().format('YYYY-MM-DD'))

    const goToToday = () => {
      dateInputValue.value = moment().format('YYYY-MM-DD')
    }

    // watch dateInput for changes, update query
     watch(dateInputValue, () => {
      updateStartDate(dateInputValue.value)
    })

    return {
      loadingRota,
      rows,
      columns,
      isTableView,
      // Changing date
      updateStartDate,
      dateInputValue,
      startingYear,
      numberOfYears,
      goToToday,
      showBackToToday,
    }
  },
})
</script>
