
import { defineComponent, ref } from 'vue'
import debounce from 'lodash/debounce'
import SearchIcon from '@/components/icons/SearchIcon.vue'

export default defineComponent({
  inheritAttrs: false,
  
  components: {
    SearchIcon,
  },

  props: {
    initialValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Search',
    },
  },

  emits: ['search'],

  setup(props, ctx) {
    const search = ref(props.initialValue)

    const initSearch = (() => {
      if (!search.value.length) ctx.emit('search', '')
      if (search.value.length >= 3 && search.value.length <= 45) ctx.emit('search', search.value)
    })

    const debouncedSearch = debounce(() => {
      initSearch()
    }, 500)

    return {
      search,
      initSearch,
      debouncedSearch,
    }
  },
})
