<template>
  <div class="md:h-screen w-screen flex">
    <div class="bg-blue-medium w-full min-h-screen md:w-7/12 lg:w-5/12 flex items-center">
      <RouterView v-slot="{ Component }">
        <transition
          name="fade"
          mode="out-in"
        >
          <component :is="Component" />
        </transition>
      </RouterView>
    </div>
    <div class="hidden md:block w-5/12 lg:w-7/12 max-h-screen relative">
      <img
        class="object-cover xxl:object-top w-full h-screen"
        :src="require('@/assets/img/auth-background.jpg')"
        alt="Offices and blue sky"
      >
      <span class="absolute top-0 right-0 w-full lg:w-10/12 xl:w-2/3 flex px-4 pt-4 text-blue-medium">
        <RotaArchitectLogoIcon />
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import RotaArchitectLogoIcon from '@/components/icons/RotaArchitectLogoIcon.vue'

export default defineComponent({
  components: {
    RotaArchitectLogoIcon,
  },
})
</script>