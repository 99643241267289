import { UserBasics } from "./users";

export enum EntryPillType {
  EARLY = 'early',
  MID = 'mid',
  LATE = 'late',
  NIGHT_ONE = 'n1',
  NIGHT_TWO = 'n2',
  ON_CALL = 'on-call',
  ABSENCE = 'absence',
  STUDY_LEAVE = 'study',
  SICKNESS = 'sickness',
  OFF = 'off',
}

export enum EntryStatus {
  DRAFT = 'draft',
  PENDING = 'pending',
  APPROVED = 'approved',
  DELETING = 'deleting',
}

export interface EntryBasics {
  entry_id: number;
  user_id: number;
  date_id: number;
  user?: UserBasics & { 
    sub_grade_id: number;
    sub_grade: string;
    grade_id: number;
    phone: string;
    email: string;
  };
  rota_id: number;
  status_id: number;
  status: EntryStatus;
  is_locum: boolean;
  with_absence_id: number;
  tag?: {
    id: number;
    label: string;
    hex: string;
  };
}

export interface Shift {
  id: number;
  name: string;
  start: string;
  end: string;
  hours: number;
  grade_id: number;
  grade: string;
  sub_grade_id: number;
  staffing_group_id: number;
  slot_id: 1 | 2 | 3 | 4 | 5 | 6;
  slot: string;
  preferred?: 0 | 1 | null;
  is_primary: 0 | 1;
}

export interface TypedShift extends Shift {
  type: 'shifts';
}

export interface ShiftEntry extends EntryBasics {
  type: 'shifts';
  shift: Shift;
  with_absence?: string;
}

export interface Absence {
  id: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
  label: string;
}

export interface TypedAbsence extends Absence {
  type: 'absences';
}

export interface AbsenceEntry extends EntryBasics {
  type: 'absences';
  absence: Absence;
}

export type Entry = ShiftEntry | AbsenceEntry;

export interface DateBasics {
  id: number;
  day_id: number;
  date: string;
  day: string;
}

export interface RosterDate<T> extends DateBasics {
  is_weekend: boolean;
  is_holiday: boolean;
  entries: T[];
}

export interface BoardDate extends DateBasics {
  is_weekend: boolean;
  is_holiday: boolean;
  entries: {
    [key: string]: ShiftEntry[];
  };
}

export interface EntryPillData {
  textContent: string;
  type: EntryPillType;
  status: EntryStatus | null;
  isLocum: boolean;
  entry_id?: number;
}

export interface RotaTableRow extends DateBasics {
  entries: EntryPillData[];
}

export interface Draftable {
  rosterable_type: string;
  rosterable_id: number;
  user_id: number | null;
  date_id: number;
  is_locum: boolean;
  rota_id: number;
  with_absence_id: number | null;
  alternate_start: string | null;
  alternate_end: string | null;
  alternate_hours: number | null;
}

export interface Updateable {
  user_id: number;
  with_absence_id: number | null;
}


export interface Taggable {
  tag_id: number | null;
}

export interface Notifiable {
  type: string;
  message: string;
}

export enum SubGrade {
  CONS = 1,
  REG_JNR = 2,
  REG_SNR = 3,
  ASOC = 4,
  SHO = 5,
  NMP = 6,
}

export enum Grade {
  CONS = 1,
  REG = 2,
  ASOC = 3,
  SHO = 4,
  NMP = 5,
}