<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 87.875 253.5 82.438"
    enable-background="new 0 87.875 253.5 82.438"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="16"
      stroke-miterlimit="10"
      x1="54.931"
      y1="128.643"
      x2="249.489"
      y2="128.643"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-width="16"
      stroke-miterlimit="10"
      points="127.892,97.567 30.611,128.643 
	127.892,158.455 "
    />
  </svg>
</template>