<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g>
      <path
        fill="none"
        stroke="currentColor"
        stroke-width="6"
        stroke-miterlimit="10"
        d="M256.824,131.164
		c0,69.247-56.112,125.365-125.367,125.365c-69.242,0-125.36-56.118-125.36-125.365c0-69.236,56.118-125.36,125.36-125.36
		C200.712,5.804,256.824,61.928,256.824,131.164z"
      />
      <g>
        <g>
          <g>
            <defs>
              <path
                id="SVGID_1_"
                d="M256.824,131.164c0,69.247-56.112,125.365-125.367,125.365c-69.242,0-125.36-56.118-125.36-125.365
						c0-69.236,56.118-125.36,125.36-125.36C200.712,5.804,256.824,61.928,256.824,131.164z"
              />
            </defs>
            <clipPath id="SVGID_2_">
              <use
                xlink:href="#SVGID_1_"
                overflow="visible"
              />
            </clipPath>
            <g clip-path="url(#SVGID_2_)">
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="9"
                stroke-miterlimit="10"
                d="M178.105,106.177
						c0,25.476-20.646,46.112-46.114,46.112c-25.47,0-46.121-20.637-46.121-46.112c0-25.47,20.65-46.114,46.121-46.114
						C157.461,60.063,178.105,80.707,178.105,106.177z"
              />
              <path
                fill="none"
                stroke="currentColor"
                stroke-width="9"
                stroke-miterlimit="10"
                d="M71.654,143.76
						c0,0,32.481,37.211,60.337,35.789c27.851-1.421,59.147-35.789,59.147-35.789s37.881,1.648,43.57,44.327
						c5.688,42.672-32.912,136.553-32.912,136.553H54.585c0,0-32.116-105.12-32.193-133.71
						C22.27,143.76,71.654,143.76,71.654,143.76z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>