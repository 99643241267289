<template>
  <form
    class="w-full md:ml-0"
    @submit.prevent="initSearch"
  >
    <InputLabel input-name="search_field">
      {{ label }}
    </InputLabel>
    <div class="relative rounded-lg w-full bg-blue-medium h-10">
      <div class="absolute inset-y-0 left-3 flex items-center pointer-events-none">
        <slot name="icon">
          <span class="w-5 h-5 flex text-white">
            <SearchIcon />
          </span>
        </slot>
      </div>
      <input
        id="search_field"
        v-model="search"
        v-bind="{ ...$attrs }"
        class="block w-full h-full bg-blue-medium pl-10 sm:pr-3 py-2 rounded-md text-white placeholder-gray-300 focus:outline-none focus:placeholder-gray-400"
        minlength="3"
        maxlength="45"
        type="text"
        @keyup="debouncedSearch"
      >
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import debounce from 'lodash/debounce'
import SearchIcon from '@/components/icons/SearchIcon.vue'

export default defineComponent({
  inheritAttrs: false,
  
  components: {
    SearchIcon,
  },

  props: {
    initialValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Search',
    },
  },

  emits: ['search'],

  setup(props, ctx) {
    const search = ref(props.initialValue)

    const initSearch = (() => {
      if (!search.value.length) ctx.emit('search', '')
      if (search.value.length >= 3 && search.value.length <= 45) ctx.emit('search', search.value)
    })

    const debouncedSearch = debounce(() => {
      initSearch()
    }, 500)

    return {
      search,
      initSearch,
      debouncedSearch,
    }
  },
})
</script>