<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-auto"
    viewBox="0 0 179.279 20"
    enable-background="new 0 0 179.279 20"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M8.646,20H0.079V8.661h3.108v8.232h5.152c1.288,0,2.417-0.331,3.388-0.994
		c0.971-0.662,1.685-1.488,2.142-2.479c0.457-0.988,0.7-2.063,0.728-3.219c0.028-1.157-0.173-2.23-0.602-3.22
		c-0.43-0.989-1.139-1.815-2.128-2.478c-0.99-0.662-2.166-0.994-3.528-0.994h-8.26V0.4h8.568c1.363,0,2.618,0.224,3.766,0.672
		c1.148,0.448,2.095,1.05,2.842,1.806c0.746,0.756,1.386,1.624,1.918,2.604c0.532,0.98,0.858,2.012,0.98,3.094
		c0.121,1.083,0.13,2.166,0.028,3.248c-0.103,1.083-0.411,2.114-0.924,3.094c-0.514,0.98-1.144,1.849-1.89,2.604
		s-1.704,1.358-2.87,1.806C11.33,19.777,10.047,20,8.646,20z"
      />
      <path
        fill="currentColor"
        d="M35.919,20l-2.016-4.703h-9.268L22.647,20h-3.416l4.648-10.919h3.416l-1.288,3.024h6.552
		C29.423,4.75,27.761,0.849,27.575,0.4h3.416L39.335,20H35.919z"
      />
      <path
        fill="currentColor"
        d="M56.303,2.192l-2.016,2.408c-1.512-1.25-3.117-1.82-4.816-1.708c-0.598,0.056-1.139,0.196-1.624,0.42
		c-0.448,0.206-0.821,0.486-1.12,0.84c-0.43,0.523-0.626,1.158-0.588,1.904c0.056,0.299,0.191,0.579,0.406,0.84
		c0.214,0.262,0.438,0.476,0.672,0.644s0.574,0.341,1.022,0.518c0.448,0.178,0.816,0.308,1.106,0.392s0.714,0.21,1.274,0.378
		c0.915,0.262,1.61,0.472,2.086,0.63c0.476,0.159,1.068,0.42,1.778,0.784c0.709,0.364,1.232,0.751,1.568,1.162
		c0.336,0.411,0.616,0.957,0.84,1.639c0.224,0.682,0.299,1.47,0.224,2.365v0.028c-0.131,0.989-0.504,1.876-1.12,2.66
		c-0.747,0.915-1.754,1.577-3.024,1.987c-1.213,0.373-2.52,0.467-3.92,0.281c-2.427-0.299-4.732-1.326-6.916-3.08l1.96-2.437
		c1.717,1.362,3.491,2.155,5.32,2.38c0.97,0.131,1.838,0.074,2.604-0.168c0.672-0.205,1.176-0.522,1.512-0.952
		c0.243-0.298,0.392-0.644,0.448-1.036c0.018-0.373-0.052-0.714-0.21-1.021c-0.159-0.309-0.346-0.561-0.56-0.756
		c-0.215-0.196-0.551-0.393-1.008-0.588c-0.458-0.197-0.835-0.346-1.134-0.449c-0.299-0.102-0.742-0.237-1.33-0.405
		s-1.032-0.298-1.33-0.392c-0.299-0.094-0.714-0.238-1.246-0.435c-0.532-0.196-0.942-0.378-1.232-0.546s-0.63-0.392-1.022-0.672
		c-0.392-0.28-0.695-0.574-0.91-0.882c-0.215-0.308-0.416-0.681-0.602-1.12c-0.187-0.438-0.318-0.91-0.392-1.414V6.28
		c-0.112-1.586,0.326-2.977,1.316-4.172c0.579-0.69,1.306-1.241,2.184-1.652c0.858-0.392,1.773-0.625,2.744-0.7
		c0.765-0.056,1.54-0.009,2.324,0.14s1.586,0.416,2.408,0.798C54.8,1.077,55.575,1.577,56.303,2.192z"
      />
      <path
        fill="currentColor"
        d="M72.851,11.74h-9.8V20h-3.108V8.661h5.096V8.632c3.92,0.019,6.524,0.028,7.812,0.028V0.4h3.08V20h-3.08
		V11.74z M59.942,5.552V0.4h3.108v5.152H59.942z"
      />
      <path
        fill="currentColor"
        d="M78.73,20v-3.107h9.856c0.709,0,1.316-0.252,1.82-0.756c0.504-0.504,0.756-1.111,0.756-1.82
		S90.91,13,90.406,12.496c-0.504-0.504-1.111-0.756-1.82-0.756H78.73v-3.08h9.1h0.756c0.709,0,1.316-0.252,1.82-0.756
		c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82c-0.504-0.504-1.111-0.756-1.82-0.756H78.73V0.4h9.828
		c1.568,0,2.907,0.556,4.018,1.666c1.11,1.111,1.666,2.45,1.666,4.018c0,1.624-0.598,2.996-1.792,4.116
		c1.194,1.12,1.792,2.492,1.792,4.116c0,1.568-0.556,2.908-1.666,4.018C91.465,19.445,90.126,20,88.558,20H78.73z"
      />
      <path
        fill="currentColor"
        d="M107.346-0.104c1.4,0,2.734,0.271,4.004,0.812c1.269,0.542,2.366,1.274,3.29,2.198
		c0.924,0.924,1.656,2.021,2.198,3.29c0.542,1.27,0.812,2.604,0.812,4.004c0,1.867-0.462,3.588-1.386,5.167
		c-0.924,1.577-2.175,2.827-3.752,3.752c-1.578,0.924-3.3,1.385-5.166,1.385c-1.867,0-3.594-0.461-5.18-1.385
		c-1.587-0.925-2.837-2.175-3.752-3.752c-0.915-1.578-1.372-3.3-1.372-5.167s0.458-3.588,1.372-5.166
		c0.915-1.577,2.165-2.828,3.752-3.752C103.752,0.358,105.479-0.104,107.346-0.104z M112.357,15.213
		c1.381-1.381,2.072-3.053,2.072-5.012c0-1.96-0.691-3.63-2.072-5.012c-1.381-1.381-3.052-2.072-5.012-2.072
		s-3.631,0.69-5.012,2.072c-1.381,1.382-2.072,3.052-2.072,5.012c0,1.959,0.69,3.631,2.072,5.012s3.052,2.072,5.012,2.072
		S110.976,16.594,112.357,15.213z"
      />
      <path
        fill="currentColor"
        d="M135.29,20l-2.016-4.703h-9.268L122.018,20h-3.416l4.648-10.919h3.416l-1.288,3.024h6.552
		c-3.136-7.354-4.798-11.256-4.984-11.704h3.416l8.344,19.6H135.29z"
      />
      <path
        fill="currentColor"
        d="M153.994,20l-5.152-8.26h-4.228V20h-3.108V8.661h9.828h0.028c0.709,0,1.316-0.252,1.82-0.756
		c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82c-0.504-0.504-1.111-0.756-1.82-0.756h-9.856V0.4h9.828
		c1.512,0,2.814,0.523,3.906,1.568c1.092,1.046,1.685,2.315,1.778,3.808v0.028c0.056,1.4-0.35,2.655-1.218,3.766
		c-0.868,1.111-1.993,1.807-3.374,2.086L157.633,20H153.994z"
      />
      <path
        fill="currentColor"
        d="M169.001,20h-8.568V8.661h3.108v8.232h5.152c1.288,0,2.417-0.331,3.388-0.994
		c0.971-0.662,1.685-1.488,2.142-2.479c0.457-0.988,0.7-2.063,0.728-3.219c0.028-1.157-0.173-2.23-0.602-3.22
		c-0.43-0.989-1.139-1.815-2.128-2.478c-0.99-0.662-2.166-0.994-3.528-0.994h-8.26V0.4h8.568c1.363,0,2.618,0.224,3.766,0.672
		c1.148,0.448,2.095,1.05,2.842,1.806c0.746,0.756,1.386,1.624,1.918,2.604c0.532,0.98,0.858,2.012,0.98,3.094
		c0.121,1.083,0.13,2.166,0.028,3.248c-0.103,1.083-0.411,2.114-0.924,3.094c-0.514,0.98-1.144,1.849-1.89,2.604
		s-1.704,1.358-2.87,1.806C171.685,19.777,170.402,20,169.001,20z"
      />
    </g>
  </svg>
</template>