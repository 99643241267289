<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="36.5 0 186 260"
    enable-background="new 36.5 0 186 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <text
      transform="matrix(1 0 0 1 23 261)"
      fill="currentColor"
      font-family="'Roboto-Regular'"
      font-size="358.0165"
    >£</text>
  </svg>
</template>