
import { defineComponent, PropType, computed } from 'vue'
import { useRouter, RouteLocationRaw } from 'vue-router'

// Components
import BackIcon from '@/components/icons/BackIcon.vue'

export default defineComponent({
  components: {
    BackIcon,
  },

  props: {
    to: {
      type: Object as PropType<RouteLocationRaw>,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },

  setup (props) {
    const router = useRouter()

    const hasHistory = computed(() => window.history.length > 2)

    const handleClick = () => {
      if (props.to) {
        router.push(props.to)
        return
      }

      if (hasHistory.value) {
        router.go(-1)
        return
      }

      router.push({ name: 'dashboard' })
    }
    
    return {
      handleClick,
    }
  },
})
