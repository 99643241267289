<template>
  <div
    class="text-white bg-green-medium p-3 rounded-lg flex items-center shadow-sm"
    :class="[extendWrapperClasses]"
  >
    <span class="w-12 h-12 mr-3">
      <SuccessIcon />
    </span>
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SuccessIcon from '@/components/icons/toast/SuccessIcon.vue'

export default defineComponent({
  components: {
    SuccessIcon,
  },

  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },
})
</script>
