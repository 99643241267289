import { Module } from 'vuex'
import { State as RootState } from '@/store'
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// Types
import { Requestable } from '@/types/ajax'
import { AuthSuccessResponse, AutoLoginable } from '@/types/auth'

/**
 * State Type
 */

export type State = AuthSuccessResponse & Requestable & AutoLoginable

export const authModule: Module<State, RootState> = {
  namespaced: true,

	state: () =>  ({
		data: {
			access_token: '',
			expires_in: null,
		},
		attemptedAutoLogin: false,
		initialPath: '',
		requesting: false,
		error: null,
  }),
  
	mutations,
  
	getters,

	actions,
}


