<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full h-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g>
      <path
        fill="none"
        stroke="currentColor"
        stroke-width="7"
        stroke-miterlimit="10"
        d="M254.025,63.566
		c0-68.576,2.614-58.235-65.965-58.235H71.65C3.056,5.331,5.67-5.01,5.67,63.566v125.912c0,68.579-10.352,65.973,58.243,65.973
		h131.885c68.58,0,58.228,2.606,58.228-65.973V63.566z"
      />
      <path
        fill="currentColor"
        d="M233.152,138.545c0.088-16.857,2.098-18.857-16.684-18.775h-78.504c-21.7-0.355-21.7-0.355-21.7,17.775
		v81.169c0.235,18.911,0.235,18.911,22.7,18.774l74.285,0.069c21,0.067,19.912,0.317,19.902-17.844V138.545z"
      />
    </g>
  </svg>
</template>