<template>
  <svg
    version="1.1"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="9.833 24.529 241.667 202.732"
    enable-background="new 9.833 24.529 241.667 202.732"
    xml:space="preserve"
  >
    <circle
      fill="currentColor"
      cx="82.384"
      cy="51.838"
      r="27.309"
    />
    <path
      fill="currentColor"
      d="M38.167,70.833c0,0,24.435,24.333,44.217,24.333s43.783-24.333,43.783-24.333s22.667,2,21.667,34.667
	l-36.053,18.453c0,0-9.281-16.786-29.128-16.786c-24.82,0-40.486,19.667-40.486,19.667L15.5,100.167
	C13.167,71.833,38.167,70.833,38.167,70.833z"
    />
    <circle
      fill="currentColor"
      cx="189.347"
      cy="103.838"
      r="27.309"
    />
    <circle
      fill="currentColor"
      cx="78.653"
      cy="149.838"
      r="27.309"
    />
    <path
      fill="currentColor"
      d="M146.5,121.837c0,0,21.768,24.333,41.551,24.333s43.783-24.333,43.783-24.333s18.333,2.663,19.666,32.667
	c0,0-27.639,23.996-62.152,25.996s-68.181-27.329-68.181-27.329C118.833,124.837,146.5,121.837,146.5,121.837z"
    />
    <path
      fill="currentColor"
      d="M36.485,168.502c0,0,21.768,24.333,41.551,24.333s43.783-24.333,43.783-24.333s18.333,2.662,19.666,32.666
	c0,0-27.639,23.996-62.152,25.996s-68.181-27.329-68.181-27.329C8.819,171.502,36.485,168.502,36.485,168.502z"
    />
  </svg>
</template>
