
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { 
  GetterTypes as ToastGetters,
  ActionTypes as ToastActions,
 } from '@/store/toast'

// Icons
import WarningIcon from '@/components/icons/toast/WarningIcon.vue'
import ErrorIcon from '@/components/icons/toast/ErrorIcon.vue'
import InfoIcon from '@/components/icons/toast/InfoIcon.vue'
import SuccessIcon from '@/components/icons/toast/SuccessIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'

export default defineComponent({
  components: {
    WarningIcon,
    ErrorIcon,
    InfoIcon,
    SuccessIcon,
    CloseIcon,
  },

  setup () {
    const store = useStore()

    const toastsArray = computed(() => store.getters[ToastGetters.ALL])

    const hideToastNotification = (uuid: string) => {
      store.dispatch(ToastActions.HIDE, uuid)
    }

    return {
      toastsArray,
      hideToastNotification,
    }
  },
})
