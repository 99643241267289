<template>
  <section class="m-6 xl:mx-12 xxl:mx-auto w-full xxl:max-w-lg">
    <span class="flex items-center justify-center w-20 h-20 mb-3 text-white">
      <IasotechLogoIcon />
    </span>
    <h1 
      class="text-white font-bold text-2xl lg:text-3xl xl:text-4xl tracking-wide leading-snug"
      :class="{ 'mb-2': hasSubtitle }"
    >
      <slot name="formTitle" />
    </h1>
    <h2
      v-if="hasSubtitle"
      class="text-gray-300 text-xl lg:text-2xl leading-snug"
    >
      <slot name="formSubtitle" />
    </h2>
    <slot />
  </section>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import IasotechLogoIcon from '@/components/icons/IasotechLogoIcon.vue'

export default defineComponent({
  components: {
    IasotechLogoIcon,
  },

  setup(_, ctx) {
    const hasSubtitle = computed(() => !!ctx.slots.formSubtitle)

    return {
      hasSubtitle,
    }
  },
})
</script>
