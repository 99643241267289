<template>
  <div class="bg-white rounded shadow-md p-2 xl:px-4 xl:py-3 max-w-full flex items-center justify-between">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup () {
    

    return {}
  },
})
</script>
