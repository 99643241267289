import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/tailwind.css'
import './assets/styles/transitions.css'

/**
 * Global components 
 * */
// Base root
import BaseBackButton from '@/components/base/BaseBackButton.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseButtonHamburger from '@/components/base/BaseButtonHamburger.vue'
import BaseCard from '@/components/base/BaseCard.vue'
import BaseCardHeader from '@/components/base/BaseCardHeader.vue'
import BaseErrorMessage from '@/components/base/BaseErrorMessage.vue'
import BaseHeader from '@/components/base/BaseHeader.vue'
import BaseIconButton from '@/components/base/BaseIconButton.vue'
import BaseLabel from '@/components/base/BaseLabel.vue'
import BaseLoading from '@/components/base/BaseLoading.vue'
import BaseModal from '@/components/base/BaseModal.vue'
import BasePageTitle from '@/components/base/BasePageTitle.vue'
import BaseSuccessMessage from '@/components/base/BaseSuccessMessage.vue'
import BaseToggle from '@/components/base/BaseToggle.vue'
import BaseToolTip from '@/components/base/BaseToolTip.vue'
import BaseWorking from '@/components/base/BaseWorking.vue'
// Base form
import DateInput from '@/components/base/form/DateInput.vue'
import InputLabel from '@/components/base/form/InputLabel.vue'
import SearchInput from '@/components/base/form/SearchInput.vue'
import SelectInput from '@/components/base/form/SelectInput.vue'
import TimeInput from '@/components/base/form/TimeInput.vue'
import VSelectInput from '@/components/base/form/VSelectInput.vue'
import VTextInput from '@/components/base/form/VTextInput.vue'
import VTimeInput from '@/components/base/form/VTimeInput.vue'
// Base layouts
import AuthFormWrapper from '@/components/base/layouts/AuthFormWrapper.vue'
import BaseViewWrapper from '@/components/base/layouts/BaseViewWrapper.vue'
// Base table
import DataTable from '@/components/base/table/DataTable.vue'


createApp(App)
  .use(store)
  .use(router)
  .component('BaseBackButton', BaseBackButton)
  .component('BaseButton', BaseButton)
  .component('BaseButtonHamburger', BaseButtonHamburger)
  .component('BaseCard', BaseCard)
  .component('BaseCardHeader', BaseCardHeader)
  .component('BaseErrorMessage', BaseErrorMessage)
  .component('BaseHeader', BaseHeader)
  .component('BaseIconButton', BaseIconButton)
  .component('BaseLabel', BaseLabel)
  .component('BaseLoading', BaseLoading)
  .component('BaseModal', BaseModal)
  .component('BasePageTitle', BasePageTitle)
  .component('BaseSuccessMessage', BaseSuccessMessage)
  .component('BaseToggle', BaseToggle)
  .component('BaseToolTip', BaseToolTip)
  .component('BaseWorking', BaseWorking)
  .component('DateInput', DateInput)
  .component('InputLabel', InputLabel)
  .component('SearchInput', SearchInput)
  .component('SelectInput', SelectInput)
  .component('TimeInput', TimeInput)
  .component('VSelectInput', VSelectInput)
  .component('VTextInput', VTextInput)
  .component('VTimeInput', VTimeInput)
  .component('AuthFormWrapper', AuthFormWrapper)
  .component('BaseViewWrapper', BaseViewWrapper)
  .component('DataTable', DataTable)
  .mount('#app')