import { State } from './'
import { State as RootState } from '@/store'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import { GetterTree } from 'vuex'

/**
 * Getter Types
 */
export enum _GetterTypes {
  ERROR = 'ERROR',
  REQUESTING = 'REQUESTING',
  IS_AUTH = 'IS_AUTH',
  TOKEN = 'TOKEN',
  TOKEN_EXPIRY = 'TOKEN_EXPIRY',
  ATTEMPTED_AUTO_LOGIN = 'ATTEMPTED_AUTO_LOGIN',
  INITIAL_PATH = 'INITIAL_PATH',
}

// PUBLIC
export enum GetterTypes {
  ERROR = 'auth/ERROR',
  REQUESTING = 'auth/REQUESTING',
  IS_AUTH = 'auth/IS_AUTH',
  TOKEN = 'auth/TOKEN',
  ATTEMPTED_AUTO_LOGIN = 'auth/ATTEMPTED_AUTO_LOGIN',
  INITIAL_PATH = 'auth/INITIAL_PATH',
}

export default {
  [_GetterTypes.ERROR]: (state: State) => state.error,

  [_GetterTypes.REQUESTING]: (state: State) => state.requesting,

  [_GetterTypes.TOKEN]: (state: State) => state.data.access_token,

  [_GetterTypes.TOKEN_EXPIRY]: (state: State) => state.data.expires_in,

  [_GetterTypes.IS_AUTH]: (
    state: State, 
    _: GetterTree<State, RootState>, 
    _2: RootState, 
    rootGetters: GetterTree<State, RootState>,
  ) => !!state.data.access_token && !!rootGetters[UserGetters.USER_ID],

  [_GetterTypes.ATTEMPTED_AUTO_LOGIN]: (state: State) => state.attemptedAutoLogin,

  [_GetterTypes.INITIAL_PATH]: (state: State) => state.initialPath,
}