<template>
  <div
    class="bg-white rounded flex flex-col justify-between"
    :class="[extendWrapperClasses]"
  >
    <table class="w-full border-separate">
      <thead
        v-if="!headless"
        class="bg-grey text-white border-b-8 border-white"
      >
        <tr v-if="loading">
          <th
            class="text-left px-4 pt-4 pb-3"
            v-for="(column, idx) in columns"
            :key="idx"
          >
            <span v-if="idx === 0">
              <BaseLoading />
            </span>
          </th>
        </tr>
        <tr v-else>
          <DataTableHeading
            v-for="column in columns"
            :key="column.name"
            :name="column.name"
            :align="column.align ?? 'left'"
            :textless="column.textlessHeading ?? false"
            :sticky-on-scroll="column.stickyOnScroll"
            :sort="sort"
            :class="[column.responsive ?? '']"
            @sort="$emit('sort', $event)"
          >
            <slot
              :name="`column-heading-${column.name}`"
              :column="column"
            >
              {{ column.label }}
            </slot>
          </DataTableHeading>
        </tr>
      </thead>

      <tbody v-if="loading">
        <tr
          v-for="(row, i) in Array(8)"
          :key="i"
        >
          <td
            v-for="(column, idx) in columns"
            :key="idx"
            class="first:rounded-l-full last:rounded-r-full px-4 py-2 pulse border-t border-white"
            :class="[i % 2 ? 'bg-blue-lightest' : 'bg-white']"
          >
            <span class="h-5 block w-full" />
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="rows.length">
        <tr
          v-for="(row, idx) in rows"
          :key="idx"
          @click="clickable ? $emit('row-clicked', row.id) : null"
          @keypress.enter="clickable ? $emit('row-clicked', row.id) : null"
          class="focus:outline-none transition-colors duration-150"
          :class="[{ 'hover:bg-blue-medium hover:text-white cursor-pointer': clickable },
                   selectedRowId === row.id ? 'bg-blue-medium text-white' : 'even:bg-blue-lightest']"
          tabindex="0"
        >
          <slot
            v-for="(column, i) in columns"
            :name="`column-wrapper.${column.name}`"
          >
            <DataTableCell
              :key="i"
              :class="[column.responsive ?? '']"
              :align="column.align ?? 'left'"
              :sticky-on-scroll="column.stickyOnScroll"
              :selected-row="selectedRowId === row.id"
              :textless="column.textless ?? false"
              :extend-classes="column.extendClasses ?? ''"
            >
              <slot
                :name="`column-${column.name}`"
                :value="row[column.name]"
                :row="row"
                :column="column"
              >
                {{ row[column.name] }}
              </slot>
            </DataTableCell>
          </slot>
        </tr>
      </tbody>
      <div v-else>
        <p
          v-if="!loading && !rows.length"
          class="py-3 pl-1"
        >
          No results found.
        </p>
      </div>
    </table>
    <DataPagination
      v-if="paginationConfig"
      :config="paginationConfig"
      :data="rows"
      @paginate="(val) => $emit('paginate', val)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DataTableHeading from './DataTableHeading.vue'
import DataTableCell from './DataTableCell.vue'
import DataPagination from './DataPagination.vue'

export default defineComponent({
  components: {
    DataTableHeading,
    DataTableCell,
    DataPagination,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    sort: {
      type: String,
      default: '',
    },
    paginationConfig: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selectedRowId: {
      type: Number,
      default: 0,
    },
    extendWrapperClasses: {
      type: String,
      default: '',
    },
    headless: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['sort', 'row-clicked', 'paginate'],
})
</script>
