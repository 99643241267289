<template>
  <div 
    class="bg-white rounded shadow-md max-w-full"
    :class="[extendWrapperClasses]"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },
})
</script>
