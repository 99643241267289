<template>
  <nav
    v-if="hasPages"
    role="navigation"
    aria-label="Pagination Navigation"
    class="w-full flex items-center justify-between mt-3"
  >
    <div class="flex justify-between flex-1">
      <span
        v-if="onFirstPage"
        class="relative inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default leading-5 rounded-md"
      >
        Previous
      </span>
      <button
        v-else
        class="relative inline-flex items-center px-4 py-2 mr-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-blue-medium focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 cursor-pointer"
        @click="$emit('paginate', previousPageNumber)"
      >
        Previous
      </button>

      <div class="hidden md:flex-1 md:flex md:flex-col md:items-center md:justify-between lg:flex-row">
        <div class="mb-4 lg:mb-0">
          <p class="text-sm text-gray-700 leading-5">
            Showing
            <span class="font-medium">{{ firstItem }}</span>
            to
            <span class="font-medium">{{ lastItem }}</span>
            of
            <span class="font-medium">{{ total }}</span>
            results
          </p>
        </div>

        <span
          v-for="(element, index) in pageLinks"
          :key="index"
        >
          <span
            v-if="typeof element === 'string'"
            aria-disabled="true"
          >
            <span class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 cursor-default leading-5">{{ element }}</span>
          </span>

          <span v-if="typeof element === 'object'">
            <span
              v-for="(url, page) in element"
              :key="page"
            >
              <span
                v-if="+page === +currentPage"
                aria-current="page"
              >
                <span class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-300 bg-white border border-gray-300 cursor-default leading-5">{{ page }}</span>
              </span>
              <button
                v-else
                class="relative inline-flex items-center px-4 py-2 -ml-px text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 hover:text-blue-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                :aria-label="`Go to page ${page}`"
                @click="$emit('paginate', page)"
              >
                {{ page }}
              </button>
            </span>
          </span>
        </span>
      </div>

      <button
        v-if="hasMorePages"
        class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 leading-5 rounded-md hover:text-blue-medium focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150 cursor-pointer"
        @click="$emit('paginate', nextPageNumber)"
      >
        Next
      </button>
      <span
        v-else
        class="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-default leading-5 rounded-md"
      >
        Next
      </span>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { PaginationConfig } from '@/types/base'

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<PaginationConfig>, // do type for this
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
  },

  emits: ['paginate'],

  setup (props) { // watch out for int/string types on these numbers
    const pageLinks = computed(() => props.config.pages)

    const currentPage = computed(() => props.config.current_page)

    const perPage = computed(() => props.config.per_page)

    const hasMorePages = computed(() => currentPage.value < props.config.last_page)

    const hasPages = computed(() => currentPage.value !== 1 || hasMorePages.value)

    const onFirstPage = computed(() => currentPage.value <= 1)

    const nextPageNumber = computed(() => {
      if (!hasMorePages.value) return null

      return currentPage.value + 1
    })

    const previousPageNumber = computed(() => {
      if (currentPage.value === 1) return null

      return currentPage.value - 1
    })

    const firstItem = computed(() => props.data.length > 0 ? (currentPage.value - 1) * perPage.value + 1 : null)

    const lastItem = computed(() => props.data.length > 0 && firstItem.value ? firstItem.value + props.data.length - 1 : null)

    const total = computed(() => props.config.total)

    return {
      pageLinks,
      currentPage,
      hasMorePages,
      hasPages,
      onFirstPage,
      nextPageNumber,
      previousPageNumber,
      firstItem,
      lastItem,
      total,
    }
  },
})
</script>
