import { createStore, createLogger } from 'vuex'

import { 
	authModule, 
	State as AuthState,
} from './auth'

import { 
	userModule, 
	State as UserState,
} from './user'

import { 
	baseModule, 
	State as BaseState,
} from './base'

import { 
	toastModule, 
	State as ToastState,
} from './toast'

export type State = {
	auth: AuthState;
	user: UserState;
	base: BaseState;
	toast: ToastState;
}

export default createStore({
	plugins: 
		process.env.NODE_ENV === 'production'
			? []
			: [createLogger()],
	modules: {
		auth: authModule,
		user: userModule,
		base: baseModule,
		toast: toastModule,
	},
})
