
import { defineComponent, computed } from 'vue'
import nameToInitials from '@/utils/nameToInitials'
import { useRoute } from 'vue-router'

// Store
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import { GetterTypes as BaseGetters } from '@/store/base'
import { ActionTypes as BaseActions } from '@/store/base'

// Components
import TheUserMenu from '@/components/singletons/TheUserMenu.vue'

// Icons
import RotaArchitectLogoIcon from '@/components/icons/RotaArchitectLogoIcon.vue'
import DashboardHeaderIcon from '@/components/icons/headers/DashboardHeaderIcon.vue'
import PersonnelHeaderIcon from '@/components/icons/headers/PersonnelHeaderIcon.vue'
import MasterRotaHeaderIcon from '@/components/icons/headers/MasterRotaHeaderIcon.vue'
import DailyRotaHeaderIcon from '@/components/icons/headers/DailyRotaHeaderIcon.vue'
import RequestsHeaderIcon from '@/components/icons/headers/RequestsHeaderIcon.vue'
import MyProfileHeaderIcon from '@/components/icons/headers/MyProfileHeaderIcon.vue'


export default defineComponent({
  components: {
    RotaArchitectLogoIcon,
    DashboardHeaderIcon,
    PersonnelHeaderIcon,
    MasterRotaHeaderIcon,
    DailyRotaHeaderIcon,
    RequestsHeaderIcon,
    MyProfileHeaderIcon,
    TheUserMenu,
  },

  setup () {
    const store = useStore()
    const route = useRoute()

    const user = computed(() => store.getters[UserGetters.USER])

    const toggleUserMenu = () => {
      store.getters[BaseGetters.USER_MENU_OPEN]
        ? store.dispatch(BaseActions.HIDE_USER_MENU)
        : store.dispatch(BaseActions.SHOW_USER_MENU)
    }

    const pageHeaderIcon = computed(() => `${String(route.name)}-header-icon`)

    return {
      toggleUserMenu,
      user,
      initials: nameToInitials(user.value.name),
      pageHeaderIcon,
    }
  },
})
