
import { defineComponent, computed, ref, onMounted, watch } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    startingYear: {
      type: Number,
      default: 2021,
    },
    numberOfYears: {
      type: Number,
      default: 20,
    },
  },

  emits: ['update:modelValue'],

  setup (props, ctx) {
    const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

    const dateValue = computed(() => {
      return moment(props.modelValue, props.dateFormat).isValid() 
        ? moment(props.modelValue, props.dateFormat)
        : moment()
    })

    const yearsOptions = computed(() => {
      const min = props.startingYear

      const max = props.startingYear + props.numberOfYears

      const years: number[] = []

      for (let year = min; year <= max; year++) {
        years.push(year)
      }

      return years
    })

    const daysInMonth = computed(() => dateValue.value.daysInMonth())

    const dayToString = (day: number) => day < 10 ? `0${day}` : day.toString()
    
    const date = ref<HTMLSelectElement>()
    const month = ref<HTMLSelectElement>()
    const year = ref<HTMLSelectElement>()

    const updateElements = () => {
      (date.value as HTMLSelectElement).value = dateValue.value.date().toString();
      (month.value as HTMLSelectElement).value = dateValue.value.month().toString();
      (year.value as HTMLSelectElement).value = dateValue.value.year().toString();
    }

    const editDateValue = (unit: 'month' | 'date' | 'year', value: number) => {
      const newValue = dateValue.value.clone().set(unit, value)
      ctx.emit('update:modelValue', newValue.format(props.dateFormat))
    }

    onMounted(updateElements)

    watch(dateValue, updateElements)

    return {
      date,
      month,
      year,
      yearsOptions,
      MONTHS,
      daysInMonth,
      dayToString,
      editDateValue,
    }
  },
})
