<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full h-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <path
      fill="#669AA9"
      d="M257.5,248.019c0,6.064-2.88,8.981-8.897,8.981H98.398c-6.019,0-8.898-2.917-8.898-8.981v-57.537
	c0-6.064,2.879-8.981,8.898-8.981h150.204c6.018,0,8.897,2.917,8.897,8.981V248.019z"
    />
    <path
      fill="#FFFFFF"
      d="M83,248.019c0,6.064-2.5,8.981-8.222,8.981H10.722c-5.472,0-8.222-2.917-8.222-8.981v-56.537
	c0-6.064,2-9.981,9.222-9.981h63.057c6.972,0,8.222,2.917,8.222,8.981V248.019z"
    />
    <path
      fill="#FFFFFF"
      d="M257.5,71.519c0,6.064-2.5,8.981-8.222,8.981h-64.057c-5.472,0-8.222-2.917-8.222-8.981V14.981
	C177,8.917,179,5,186.222,5h63.057c6.972,0,8.222,2.917,8.222,8.981V71.519z"
    />
    <path
      fill="#A1DCF3"
      d="M170.5,71.519c0,6.064-2.88,8.981-8.897,8.981H11.398c-6.019,0-8.898-2.917-8.898-8.981V13.981
	C2.5,7.917,5.379,5,11.398,5h150.204c6.018,0,8.897,2.917,8.897,8.981V71.519z"
    />
    <path
      fill="#FFFFFF"
      d="M257.75,158.769c0,6.064-2.88,8.981-8.897,8.981H11.648c-6.019,0-8.898-2.917-8.898-8.981v-57.537
	c0-6.064,2.879-8.981,8.898-8.981h237.204c6.018,0,8.897,2.917,8.897,8.981V158.769z"
    />
  </svg>
</template>