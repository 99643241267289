
import { defineComponent } from 'vue'
import WarningIcon from '@/components/icons/WarningIcon.vue'

export default defineComponent({
  components: {
    WarningIcon,
  },

  props: {
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },
})
