<template>
  <Teleport
    v-if="working"
    to="body"
  >
    <div class="fixed bottom-10 right-10 h-10 bg-blue-medium p-2 rounded-full bg-opacity-50">
      <BaseLoading />
    </div>
  </Teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    working: {
      type: Boolean,
      required: true,
    },
  },
})
</script>
