<template>
  <teleport to="body">
    <transition name="fade">
      <div
        v-if="show"
        :class="[baseClasses, sizeClasses, extendWrapperClasses]"
      >
        <slot />
      </div>
    </transition>
    <div
      v-if="show"
      class="fixed z-40 top-0 left-0 w-screen h-screen bg-backdrop cursor-pointer"
      @click="$emit('hide')"
    />
  </teleport>
</template>

<script lang="ts">
import { StringMap } from '@/types/base'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (val: string) => ['sm', 'md', 'lg'].includes(val),
      default: 'md',
    },
    extendWrapperClasses: {
      type: String,
      default: 'bg-white shadow-xl px-5 py-4',
    },
  },

  emits: ['hide'],

  setup(props) {
    const sizeClasses: StringMap = {
      sm: 'sm:max-w-lg',
      md: 'sm:max-w-lg md:max-w-2xl',
      lg: 'sm:max-w-lg md:max-w-2xl lg:max-w-5xl',
    }

    return {
      baseClasses: 'fixed z-50 transform -translate-y-1/2 -translate-x-1/2 top-1/2 left-1/2 w-full max-w-modal p-1 max-h-screen overflow-y-auto',
      sizeClasses: sizeClasses[props.size],
    }
  },
})
</script>
