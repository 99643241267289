<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g
      id="Positive_Toast"
      display="none"
    >
      <path
        display="inline"
        fill="#FFFFFF"
        d="M258.793,129.894c0,71.187-57.712,128.897-128.896,128.897
		C58.707,258.791,1,201.081,1,129.894C1,58.709,58.707,1,129.896,1C201.081,1,258.793,58.709,258.793,129.894z"
      />
      <polyline
        display="inline"
        fill="none"
        stroke="#49B649"
        stroke-width="22"
        stroke-miterlimit="10"
        points="53.364,141.734 
		93.28,185.429 212.001,84.718 	"
      />
    </g>
    <g id="Negative_Toast">
      <path
        fill="#FFFFFF"
        d="M258.896,129.999c0,71.187-57.712,128.896-128.896,128.896c-71.189,0-128.896-57.71-128.896-128.896
		C1.104,58.813,58.811,1.104,130,1.104C201.185,1.104,258.896,58.813,258.896,129.999z"
      />
      <path
        fill="#EC1E24"
        d="M111.436,40.007c0,0,5.066-5.304,20.821-5.715c15.755-0.411,22.292,5.4,22.292,5.4l-10.111,139.158
		c0,0-5.542-2.89-13.354-3.027c-7.811-0.137-13.07,3.22-13.07,3.22L111.436,40.007z"
      />
      <circle
        fill="#EC1E24"
        cx="131.071"
        cy="205.189"
        r="18.081"
      />
    </g>
  </svg>
</template>