import api from './'
import { Authable, Registerable, Forgettable, Resetable } from '@/types/auth'

export default {
  login(payload: Authable) {
    return api.post('/login', payload)
  },

  refresh(token: string) {
    return api.post('/login/refresh', {
      refresh_token: token,
    })
  },

  register(payload: Registerable) {
    return api.post('/register', payload)
  },

  resetPassword(payload: Resetable) {
    return api.post('/password/reset', payload)
  },

  forgotPassword(payload: Forgettable) {
    return api.post('/password/forgot', payload)
  },

  logout() {
    return api.post('/logout')
  },
}