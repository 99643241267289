<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-width="13"
      stroke-miterlimit="10"
      points="151.5,251.5 251.5,251.5 251.5,8.5 
	151.5,8.5 "
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-width="25"
      stroke-miterlimit="10"
      x1="210"
      y1="130"
      x2="28.5"
      y2="130"
    />
    <polyline
      fill="none"
      stroke="currentColor"
      stroke-width="25"
      stroke-miterlimit="10"
      points="89.5,91.75 26.5,130 89.5,171 "
    />
  </svg>
</template>