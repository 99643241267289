<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-auto"
    viewBox="0 0 116.517 20"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M15.591,5.805c0.074,1.605-0.448,2.996-1.568,4.172c-1.12,1.176-2.492,1.764-4.116,1.764h-6.72V20H0.079
		V8.661h9.828h0.028c0.709,0,1.316-0.252,1.82-0.756c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82
		c-0.504-0.504-1.111-0.756-1.82-0.756H0.079V0.4h9.828c1.512,0,2.814,0.523,3.906,1.568c1.092,1.046,1.685,2.315,1.778,3.808V5.805
		z"
      />
      <path
        fill="currentColor"
        d="M30.878,20l-5.152-8.26h-4.228V20H18.39V8.661h9.828h0.028c0.709,0,1.316-0.252,1.82-0.756
		c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82c-0.504-0.504-1.111-0.756-1.82-0.756H18.39V0.4h9.828
		c1.512,0,2.814,0.523,3.906,1.568c1.092,1.046,1.685,2.315,1.778,3.808v0.028c0.056,1.4-0.35,2.655-1.218,3.766
		c-0.868,1.111-1.993,1.807-3.374,2.086L34.518,20H30.878z"
      />
      <path
        fill="currentColor"
        d="M46.755-0.104c1.4,0,2.734,0.271,4.004,0.812c1.269,0.542,2.366,1.274,3.29,2.198
		c0.924,0.924,1.656,2.021,2.198,3.29c0.542,1.27,0.812,2.604,0.812,4.004c0,1.867-0.462,3.588-1.386,5.167
		c-0.924,1.577-2.175,2.827-3.752,3.752c-1.578,0.924-3.3,1.385-5.166,1.385c-1.867,0-3.594-0.461-5.18-1.385
		c-1.587-0.925-2.837-2.175-3.752-3.752c-0.915-1.578-1.372-3.3-1.372-5.167s0.458-3.588,1.372-5.166
		c0.915-1.577,2.165-2.828,3.752-3.752C43.161,0.358,44.888-0.104,46.755-0.104z M51.767,15.213
		c1.381-1.381,2.072-3.053,2.072-5.012c0-1.96-0.691-3.63-2.072-5.012c-1.381-1.381-3.052-2.072-5.012-2.072
		s-3.631,0.69-5.012,2.072c-1.381,1.382-2.072,3.052-2.072,5.012c0,1.959,0.69,3.631,2.072,5.012s3.052,2.072,5.012,2.072
		S50.385,16.594,51.767,15.213z"
      />
      <path
        fill="currentColor"
        d="M59.858,0.4h15.12v3.108h-15.12V0.4z M62.966,20h-3.108V8.661h15.12v3.08H62.966V20z"
      />
      <path
        fill="currentColor"
        d="M80.886,20h-3.108V0.4h3.108V20z"
      />
      <path
        fill="currentColor"
        d="M97.967,20h-14.28V0.4h3.108v16.492h11.172V20z"
      />
      <path
        fill="currentColor"
        d="M100.766,0.4h15.12v3.108h-15.12V0.4z M115.886,16.893V20h-15.12V8.661h15.12v3.08h-12.012v5.152H115.886z"
      />
    </g>
  </svg>
</template>