
import { defineComponent, PropType, computed } from 'vue'
import { SelectOption } from '@/types/form'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'
import { StringMap } from '@/types/base'

export default defineComponent({
  inheritAttrs: false,

  components: {
    CloseIcon,
  },
  
  props: {
    loadingOptions: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    theme: {
      type: String,
      validator: (val: string) => ['filter', 'base'].includes(val),
      default: 'filter',
    },
    size: {
      type: String,
      validator: (val: string) => ['sm', 'full'].includes(val),
      default: 'full',
    },
    valid: {
      type: Boolean,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {

    const loadingOrHasOptions = computed(() => props.loadingOptions || !!props.options.length)

    const wrapperClasses: StringMap = {
      filter: 'w-full',
      base: 'w-full md:flex',
    }

    const labelClasses: StringMap = {
      filter: 'text-blue-medium text-sm italic mb-1 block',
      base: 'w-1/3 font-bold text-right italic text-gray-900 mt-3 mr-4',
    }

    const inputThemeClasses: StringMap = {
      filter: 'bg-blue-medium text-white',
      base: 'bg-white text-gray-900',
    }

     const inputSizeClasses: StringMap = {
      sm: 'h-8 text-sm',
      full: 'h-10',
    }

    const closeButtonClasses: StringMap = {
      filter: 'text-white hover:text-gray-300',
      base: 'text-blue-medium hover:text-blue-dark',
    }

    const iconColorClasses: StringMap = {
      filter: 'text-white',
      base: 'text-blue-medium',
    }

    const borderColorMap: StringMap = {
      filter: 'border-blue-medium',
      base: 'border-gray-300',
    }

    const borderClasses = computed(() => {
      if (props.valid) return 'border-green-medium'
      return borderColorMap[props.theme]
    })

    return {
      loadingOrHasOptions,
      wrapperClasses: wrapperClasses[props.theme],
      labelClasses: labelClasses[props.theme],
      inputThemeClasses: inputThemeClasses[props.theme],
      inputSizeClasses: inputSizeClasses[props.size],
      closeButtonClasses: closeButtonClasses[props.theme],
      iconColorClasses: iconColorClasses[props.theme],
      borderClasses,
    }
  },
})
