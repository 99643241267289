<template>
  <a
    :class="[wrapperClasses, { 'is-active' : isActive }, extendWrapperClasses]"
    role="button"
    aria-label="menu"
    aria-expanded="false"
    @click="$emit('toggle')"
  >
    <span
      :class="spanClasses"
      aria-hidden="true"
    />
    <span
      :class="spanClasses"
      aria-hidden="true"
    />
    <span
      :class="spanClasses"
      aria-hidden="true"
    />
  </a>
</template>

<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },

  emits: ['toggle'],

  data() {
    return {
      wrapperClasses: 'navbar-burger block cursor-pointer relative text-white',
      spanClasses: 'block absolute bg-white',
    }
  },
}
</script>

<style scoped>
/**
Hamburger
 */
.navbar-burger {
  height: 3.25rem;
  width: 3.25rem;
}

.navbar-burger span {
  width: 30px;
  height: 3px;
  left: calc(50% - 15px);
  transform-origin: center;
  transition-duration: 86ms;
  transition-property: background-color, opacity, transform, -webkit-transform, -o-transform;
  transition-timing-function: ease-out;
}

.navbar-burger span:nth-child(1) {
  top: calc(50% - 6px);
}
.navbar-burger span:nth-child(2) {
  top: calc(50% - 1px);
}
.navbar-burger span:nth-child(3) {
  top: calc(50% + 4px);
}

.navbar-burger.is-active span:nth-child(1) {
  transform: translateY(5.5px) rotate(45deg)
}
.navbar-burger.is-active span:nth-child(2) {
  opacity: 0;
}
.navbar-burger.is-active span:nth-child(3) {
  transform: translateY(-5.5px) rotate(-45deg);
}
</style>
