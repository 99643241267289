import UsersApi from '@/apis/rota-architect/users'

// Imported Types
import { ActionTree } from 'vuex'
import { State } from './'
import { State as RootState } from '@/store'
import { _MutationTypes } from './mutations'

/**
 * Action Types
 */
enum _ActionTypes {
  GET_SELF = 'GET_SELF',
  CLEAR_SELF = 'CLEAR_SELF',
}

// PUBLIC
export enum ActionTypes {
  GET_SELF = 'user/GET_SELF',
  CLEAR_SELF = 'user/CLEAR_SELF',
}


export default {
  async [_ActionTypes.GET_SELF](ctx) {
    ctx.commit(_MutationTypes.SET_USER_INIT)

    try {
      const res = await UsersApi.getSelf()
      ctx.commit(_MutationTypes.SET_USER_DATA, res.data)
    } catch (err) {
      ctx.commit(_MutationTypes.SET_USER_ERROR, err.response.data)
    }
  },

  [_ActionTypes.CLEAR_SELF](ctx) {
    ctx.commit(_MutationTypes.CLEAR_USER_DATA)
  },
} as ActionTree<State, RootState>