<template>
  <div class="w-full">
    <label
      v-if="label"
      class="text-blue-medium text-sm italic mb-1 block"
    >
      {{ label }}
    </label>

    <div class="flex bg-blue-medium rounded-lg h-10 px-1 border-2 border-blue-medium">
      <select
        class="block placeholder-gray-300 focus:outline-blue-dark w-full h-full py-1 px-2 text-white bg-blue-medium text-sm"
        @change="(e) => editTimeValue('hour', e.target.value)"
        ref="hour"
        :disabled="disabled"
      >
        <option
          v-for="hour in hourOptions"
          :key="hour"
          :value="hour"
        >
          {{ hour }}
        </option>
      </select>
      <select
        class="block placeholder-gray-300 focus:outline-blue-dark w-full h-full py-1 px-2 text-white bg-blue-medium text-sm"
        @change="(e) => editTimeValue('minute', e.target.value)"
        ref="minutes"
        :disabled="disabled"
      >
        <option
          v-for="min in minuteOptions"
          :key="min"
          :value="+min"
        >
          {{ min }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    timeFormat: {
      type: String,
      default: 'HH:mm:ss',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['update:modelValue'],

  setup (props, ctx) {

    const timeValue = computed(() => {
      return moment(props.modelValue, props.timeFormat).isValid() 
        ? moment(props.modelValue, props.timeFormat)
        : moment()
    })

    const hourToString = (hour: number) => hour < 10 ? `0${hour}` : hour.toString()

    const hour = ref<HTMLSelectElement>()
    const minutes = ref<HTMLSelectElement>()

    const updateElements = () => {
      (hour.value as HTMLSelectElement).value = hourToString(timeValue.value.hour());
      (minutes.value as HTMLSelectElement).value = timeValue.value.minutes().toString();
    }

    const editTimeValue = (unit: 'hour' | 'minute', value: number) => {
      const newValue = timeValue.value.clone().set(unit, value)
      ctx.emit('update:modelValue', newValue.format(props.timeFormat))
    }

    onMounted(updateElements)

    watch(timeValue, updateElements)

    return {
      editTimeValue,
      hour,
      minutes,
      hourOptions: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
        '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      minuteOptions: ['00', '15', '30', '45'],
    }
  },
})
</script>
