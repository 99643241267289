<template>
  <span
    class="tooltip bg-grey p-1 rounded-sm border-gray-500 text-center text-white shadow-md"
    :class="sizeClasses"
  >
    <slot />
  </span>
</template>

<script lang="ts">
import { StringMap } from '@/types/base'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: String,
      validator: (val: string) => ['md', 'full'].includes(val),
      default: 'full',
    },
  },


  setup (props) {
    const sizeMap: StringMap = {
      'md': 'w-40',
      'full': 'w-64',
    }

    return {
      sizeClasses: sizeMap[props.size],
    }
  },
})
</script>

<style scoped>
.tooltip {
  top: 120%;
  left: 0%;
  z-index: 1;
  transition: 200ms opacity 0.5s ease-in;
}

.tooltip::after {
  content: " ";
  position: absolute;
  bottom: 100%;  /* At the top of the tooltip */
  left: 20%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #3F4040 transparent;
}
</style>