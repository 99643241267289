import api from '@/apis/rota-architect'
import AuthApi from '@/apis/rota-architect/auth'

// Imported Types
import { Authable } from '@/types/auth'
import { ActionTree } from 'vuex'
import { State } from './'
import { State as RootState } from '@/store'
import { _MutationTypes } from './mutations'
import { ActionTypes as UserActionTypes } from '@/store/user/actions'

/**
 * Action Types
 */
enum _ActionTypes {
  ATTEMPT_LOGIN = 'ATTEMPT_LOGIN',
  ATTEMPT_REFRESH = 'ATTEMPT_REFRESH',
  ATTEMPT_AUTO_LOGIN_START = 'ATTEMPT_AUTO_LOGIN_START',
  ATTEMPT_AUTO_LOGIN_COMPLETE = 'ATTEMPT_AUTO_LOGIN_COMPLETE',
  AUTHENTICATE = 'AUTHENTICATE',
  REFRESH = 'REFRESH',
  LOGOUT = 'LOGOUT',
}

// PUBLIC
export enum ActionTypes {
  ATTEMPT_LOGIN = 'auth/ATTEMPT_LOGIN',
  ATTEMPT_REFRESH = 'auth/ATTEMPT_REFRESH',
  ATTEMPT_AUTO_LOGIN_START = 'auth/ATTEMPT_AUTO_LOGIN_START',
  ATTEMPT_AUTO_LOGIN_COMPLETE = 'auth/ATTEMPT_AUTO_LOGIN_COMPLETE',
  REFRESH = 'auth/REFRESH',
  LOGOUT = 'auth/LOGOUT',
}

let timer: number

export default {
  async [_ActionTypes.ATTEMPT_LOGIN](ctx, payload: Authable) {
    ctx.commit(_MutationTypes.SET_AUTH_INIT)

    try {
      // Authenticate with server
      const res = await AuthApi.login(payload)
      await ctx.dispatch(_ActionTypes.AUTHENTICATE, res)
    } catch (err) {
      ctx.commit(_MutationTypes.SET_AUTH_ERROR, err.response.data)
    }
  },

  async [_ActionTypes.ATTEMPT_REFRESH](ctx) {
    const refresh_token = localStorage.getItem('refresh_token')

    // if a valid refresh token, attempt to log the User in.
    if (refresh_token) {
      try {
        // attempt to refresh access_token
        const res = await AuthApi.refresh(refresh_token)
        await ctx.dispatch(_ActionTypes.AUTHENTICATE, res)
      } catch (error) {
        // Clear auth state, refreshToken expired or invalid
        ctx.dispatch(_ActionTypes.LOGOUT)
      }
    }
  },

  async [_ActionTypes.AUTHENTICATE](ctx, payload)  {
    // Persist to Local Storage.
    localStorage.setItem('access_token', payload.data.access_token)
    localStorage.setItem('refresh_token', payload.data.refresh_token)

    // Initiate token refresh counter
    const refreshIn = (payload.data.expires_in - 10) * 1000
    timer = setTimeout(() => {
      ctx.dispatch(_ActionTypes.ATTEMPT_REFRESH)
    }, refreshIn)

    // Set auth headers on global axios instance
    api.defaults.headers.common['Authorization'] = `Bearer ${payload.data.access_token}`

    // Add to application state
    ctx.commit(_MutationTypes.SET_AUTH_DATA, payload)

    // Fetch User profile.
    await ctx.dispatch(UserActionTypes.GET_SELF, null, { root: true })
  },

  async [_ActionTypes.ATTEMPT_AUTO_LOGIN_START](ctx, payload) {
    ctx.commit(_MutationTypes.SET_INITIAL_PATH, payload)

    try {
      await ctx.dispatch(_ActionTypes.ATTEMPT_REFRESH)
      ctx.dispatch(_ActionTypes.ATTEMPT_AUTO_LOGIN_COMPLETE)
    } catch (error) {
      // Handle error globally?
    }
  },

  [_ActionTypes.ATTEMPT_AUTO_LOGIN_COMPLETE](ctx) {
    ctx.commit(_MutationTypes.SET_ATTEMPTED_AUTO_LOGIN)
  },

  [_ActionTypes.LOGOUT](ctx) {
    AuthApi.logout() // can fail silently.
    ctx.commit(_MutationTypes.CLEAR_AUTH_DATA)
    ctx.dispatch(UserActionTypes.CLEAR_SELF, null, { root: true })

    // Clear to Local Storage.
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')

    // Stop the token refresh timer
    clearTimeout(timer)

    // Set auth headers on global axios instance
    api.defaults.headers.common['Authorization'] = ''
  },
} as ActionTree<State, RootState>