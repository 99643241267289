import { ref } from 'vue';
import moment from 'moment'

// Hooks
import useDispatch from '@/hooks/useToasts'

// API's
import PeriodsAPI from '@/apis/rota-architect/periods'
import parseErrorMap from '@/utils/parseErrorMap';

export default function usePeriodStartDate(periodId = 1) {
  const loading = ref(true)
  const startDate = ref(moment().format('YYYY-MM-DD')) // default to today's date.
  
  const { errorToast } = useDispatch()

  PeriodsAPI.show(periodId)
      .then((res) => {
        startDate.value = moment(res.data.start_date).format('YYYY-MM-DD')
      })
      .catch((err) => {
        errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loading.value = false
      })


  return {
    loading,
    startDate,
  }
}