<template>
  <label
    :for="inputName"
    class="text-blue-medium text-sm italic mb-1 block"
  >
    <slot />
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    inputName: {
      type: String,
      default: '',
    },
  },
})
</script>