import axios from 'axios'
import applyTokenRefeshInterceptor from '@/apis/axios.refresh'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
})

applyTokenRefeshInterceptor(apiClient)

export default apiClient
