import moment from 'moment'
import { RosterDate, EntryPillType, Shift, Entry } from "@/types/roster"
import { computed, Ref } from 'vue'
import shiftNameToInitials from '@/utils/shiftNameToInitials'

export const shiftTextContent = (shift: Shift, name: 'slot' | 'name' = 'name', initialize = false) => {
  const nameString = initialize ? shiftNameToInitials(shift[name]) : shift[name]
  const start = moment(shift.start, 'HH:mm:ss').format('HH:mm')
  const end = moment(shift.end, 'HH:mm:ss').format('HH:mm')
  return `${nameString} (${start}-${end})`
}

export const shiftPillType = (slot_id: number) => {
  switch (slot_id) {
    case 1:
      return EntryPillType.EARLY
    case 2:
      return EntryPillType.MID
    case 3:
      return EntryPillType.LATE
    case 4:
      return EntryPillType.NIGHT_ONE
    case 5:
      return EntryPillType.NIGHT_TWO
    case 6:
      return EntryPillType.ON_CALL
    default:
      return EntryPillType.OFF
  }
}

export const absencePillType = (absence_id: number) => {
  switch (absence_id) {
    case 1:
      return EntryPillType.SICKNESS
    case 6:
      return EntryPillType.STUDY_LEAVE
    default:
      return EntryPillType.ABSENCE
  }
}

export const entryPillType = (entry: Entry) => {
  switch (entry.type) {
    case 'shifts':
      return shiftPillType(entry.shift.slot_id)
    case 'absences':
      return absencePillType(entry.absence.id)
    default: return
  }
}

export const entryPillLabel = (entry: Entry, name: 'slot' | 'name' = 'slot') => {
  switch (entry.type) {
    case 'shifts':
      return shiftTextContent(entry.shift, name)
    case 'absences':
      return entry.absence.label
    default: return
  }
}

/**  
 * Returns rows and columns after being given a roster
 * 
 * 'entries' will be an array, with length 1 or 2 depending on number of pills to display.
 */
export default function useSingleUserRotaTable(
    data: Ref<RosterDate<Entry>[]>, 
    loadingData: Ref<boolean>,
    withEntry = false,
  ) {
    
  const mapEntriesToPillData = (entries: Entry[]) => {
    if (!entries.length) {
      return [{
        textContent: '-',
        type: EntryPillType.OFF,
        status: null,
      }]
    }

    return entries.map((entryData) => {
      if (entryData.type === 'absences') {
        const entry_id = withEntry ? { entry_id : entryData.entry_id } : {}

        return {
          textContent: entryData.absence.label,
          type: absencePillType(entryData.absence.id),
          status: entryData.status,
          isLocum: false,
          ...entry_id,
        }
      }

      if (entryData.type === 'shifts') {
        const entry_id = withEntry ? { entry_id: entryData.entry_id } : {}

        return {
          textContent: shiftTextContent(entryData.shift),
          type: shiftPillType(entryData.shift.slot_id),
          status: entryData.status,
          isLocum: !!entryData.is_locum,
          isWithAbsence: !!entryData.with_absence_id,
          withAbsence: entryData.with_absence ?? '',
          ...entry_id,
        }
      }
    })
  }

  const columns = computed(() => ([
    { name: 'date', label: 'Date', align: 'center', extendClasses: 'w-30' },
    { name: 'entries', label: '', textless: true, extendClasses: 'w-80' },
    // { name: 'notes', label: 'Notes' },
  ]))

  const rows = computed(() => {
    if (loadingData.value || !data.value.length) return []

    return data.value.map((item: RosterDate<Entry>) => {

      return {
        id: item.id,
        date: moment(item.date).format('DD/MM/YY'),
        isHoliday: item.is_holiday,
        day: item.day,
        day_id: item.day_id,
        entries: mapEntriesToPillData(item.entries), // return entries array either way.
      }
    })
  })

  return {
    rows,
    columns,
    shiftTextContent,
    shiftPillType,
    absencePillType,
  }
}