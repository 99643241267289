
import { computed, defineComponent } from 'vue'
import moment from 'moment'
import useRole from '@/hooks/useRole'
import { NavItem } from '@/types/base'

// Store
import { useStore } from 'vuex'
import { ActionTypes as BaseActions } from '@/store/base'
import { GetterTypes as BaseGetters } from '@/store/base'

// Components
import TheSideDrawerNavItem from '@/components/singletons/TheSideDrawerNavItem.vue'


export default defineComponent({
  components: {
    TheSideDrawerNavItem,
  },

  setup () {
    const store = useStore()
    const { isStaff, isDesk } = useRole()

    const sideDrawerOpen = computed(() => store.getters[BaseGetters.SIDE_DRAWER_OPEN])

    const toggleSideDrawerOpen = () => {
      store.dispatch(
        sideDrawerOpen.value 
          ? BaseActions.HIDE_SIDE_DRAWER 
          : BaseActions.SHOW_SIDE_DRAWER,
      )
    }

    return {
      primaryNavItems: [
        {
          label: 'Dashboard',
          icon: 'dashboard',
          routeName: 'dashboard',
          hidden: false,
        },
        {
          label: 'Personnel',
          icon: 'personnel',
          routeName: 'personnel',
          hidden: isStaff.value || isDesk.value,
        },
        {
          label: 'Master Rota',
          icon: 'master-rota',
          routeName: 'master-rota',
          hidden: isStaff.value  || isDesk.value,
        },
        {
          label: 'Daily Rota',
          icon: 'daily-rota',
          routeName: 'daily-rota',
          hidden: false,
          query: {
            date: moment().format('YYYY-MM-DD'),
          },
        },
        {
          label: 'Requests',
          icon: 'requests',
          routeName: 'requests',
          hidden: isStaff.value  || isDesk.value,
        },
      ] as NavItem[],
      secondaryNavItems: [
        {
          label: 'View Profile',
          icon: 'profile',
          routeName: 'my-profile',
          hidden: false,
        },
        {
          label: 'Log out',
          icon: 'logout',
          routeName: 'logout',
          hidden: false,
        },
      ] as NavItem[],
      sideDrawerOpen,
      toggleSideDrawerOpen,
    }
  },
})
