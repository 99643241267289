<template>
  <button
    type="button"
    class="relative inline-flex flex-shrink-0 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline"
    :class="[{
      'bg-blue-medium': !value && alternate,
      'bg-blue-light': value || !alternate,
      'cursor-not-allowed': readonly,
    }, wrapperClasses, extendWrapperClasses]"
    :disabled="readonly"
    @click="$emit('toggle', value)"
  >
    <span
      aria-hidden="true"
      class="inline-block rounded-full bg-white shadow transform transition ease-in-out duration-200"
      :class="[toggleClasses, {
        'translate-x-0': value,
        'translate-x-3': size === 'sm' && !value,
        'translate-x-5': size === 'md' && !value,
      }]"
    />
  </button>
</template>

<script lang="ts">  
import { StringMap } from '@/types/base'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    alternate: {
      type: Boolean,
      default: true,
    },
     size: {
      type: String,
      validator: (val: string) => ['sm', 'md'].includes(val),
      default: 'md',
    },
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },

  emits: ['toggle'],

  setup(props) {
    const wrapperSizeClasses: StringMap = {
      sm: 'h-4 w-7 border', 
      md: 'h-6 w-11 border-2',
    }

    const toggleSizeClasses: StringMap = {
      sm: 'w-3-1/2 h-3-1/2',
      md: 'h-5 w-5',
    }

    return {
      wrapperClasses: wrapperSizeClasses[props.size],
      toggleClasses: toggleSizeClasses[props.size],
    }
  },
})
</script>
