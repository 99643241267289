
import { defineComponent } from 'vue'
import DataTableHeading from './DataTableHeading.vue'
import DataTableCell from './DataTableCell.vue'
import DataPagination from './DataPagination.vue'

export default defineComponent({
  components: {
    DataTableHeading,
    DataTableCell,
    DataPagination,
  },

  props: {
    columns: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    sort: {
      type: String,
      default: '',
    },
    paginationConfig: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    selectedRowId: {
      type: Number,
      default: 0,
    },
    extendWrapperClasses: {
      type: String,
      default: '',
    },
    headless: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['sort', 'row-clicked', 'paginate'],
})
