
import { defineComponent, PropType } from 'vue'
import { SelectOption } from '@/types/form'
import { useField } from 'vee-validate'

export default defineComponent({

  props: {
     loadingOptions: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    name: { // allows browser autocompletion
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },

  setup (props) {
    const { 
      value: inputValue, 
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        initialValue: props.value,
      },
    ) 

    return {
      handleBlur,
      handleChange,
      errorMessage,
      inputValue,
      meta,
    }
  },
})
