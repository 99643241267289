<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full h-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g>
      <g>
        <g>
          <g>
            <g>
              <defs>
                <path
                  id="SVGID_1_"
                  d="M281.824,108.316c0,81.867-66.338,148.213-148.215,148.213c-81.862,0-148.208-66.346-148.208-148.213
							c0-81.855,66.346-148.208,148.208-148.208C215.486-39.892,281.824,26.461,281.824,108.316z"
                />
              </defs>
              <clipPath id="SVGID_2_">
                <use
                  xlink:href="#SVGID_1_"
                  overflow="visible"
                />
              </clipPath>
              <g clip-path="url(#SVGID_2_)">
                <path
                  fill="currentColor"
                  d="M188.76,78.774c0,30.119-24.409,54.517-54.52,54.517c-30.112,0-54.527-24.398-54.527-54.517
							c0-30.111,24.414-54.518,54.527-54.518C164.353,24.256,188.76,48.663,188.76,78.774z"
                />
                <path
                  fill="currentColor"
                  d="M62.906,123.207c0,0,38.401,43.993,71.334,42.312c32.926-1.68,69.927-42.312,69.927-42.312
							s44.785,1.948,51.51,52.406c6.726,50.449-38.909,161.441-38.909,161.441H42.726c0,0-37.969-124.279-38.06-158.08
							C4.521,123.207,62.906,123.207,62.906,123.207z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>