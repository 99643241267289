<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 254.25 216.25"
    enable-background="new 0 0 254.25 216.25"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        x="-3"
        y="-28.25"
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g id="Layer_2">
      <g>
        <line
          fill="none"
          stroke="currentColor"
          stroke-width="35"
          stroke-miterlimit="10"
          x1="242.74"
          y1="14.577"
          x2="12.058"
          y2="202.086"
        />
        <line
          fill="none"
          stroke="currentColor"
          stroke-width="35"
          stroke-miterlimit="10"
          x1="12.058"
          y1="14.577"
          x2="242.74"
          y2="201.815"
        />
      </g>
    </g>
  </svg>
</template>