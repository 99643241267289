<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g>
      <path
        fill="#A1DCF3"
        d="M257.348,39.94c0-32.37-2.421-34.792-34.785-34.792h-47.641c-32.381,0-34.8,2.423-34.8,34.792v48.471
		c0,32.373,2.419,34.8,34.8,34.8h47.641c32.364,0,34.785-2.427,34.785-34.8V39.94z"
      />
      <path
        fill="#679AA9"
        d="M257.348,173.597c0-32.365-2.421-34.783-34.785-34.783h-47.641c-32.381,0-34.8,2.418-34.8,34.783v48.479
		c0,32.365,2.419,34.792,34.8,34.792h47.641c32.364,0,34.785-2.427,34.785-34.792V173.597z"
      />
      <path
        fill="#A1DCF3"
        d="M119.822,173.597c0-32.365-2.42-34.783-34.785-34.783H37.396c-32.381,0-34.8,2.418-34.8,34.783v48.479
		c0,32.365,2.419,34.792,34.8,34.792h47.641c32.365,0,34.785-2.427,34.785-34.792V173.597z"
      />
      <path
        fill="#FFFFFF"
        d="M119.822,39.94c0-32.37-2.42-34.792-34.785-34.792H37.396c-32.381,0-34.8,2.423-34.8,34.792v48.471
		c0,32.373,2.419,34.8,34.8,34.8h47.641c32.365,0,34.785-2.427,34.785-34.8V39.94z"
      />
    </g>
  </svg>
</template>