  
import { StringMap } from '@/types/base'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    alternate: {
      type: Boolean,
      default: true,
    },
     size: {
      type: String,
      validator: (val: string) => ['sm', 'md'].includes(val),
      default: 'md',
    },
    extendWrapperClasses: {
      type: String,
      default: '',
    },
  },

  emits: ['toggle'],

  setup(props) {
    const wrapperSizeClasses: StringMap = {
      sm: 'h-4 w-7 border', 
      md: 'h-6 w-11 border-2',
    }

    const toggleSizeClasses: StringMap = {
      sm: 'w-3-1/2 h-3-1/2',
      md: 'h-5 w-5',
    }

    return {
      wrapperClasses: wrapperSizeClasses[props.size],
      toggleClasses: toggleSizeClasses[props.size],
    }
  },
})
