<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-auto"
    viewBox="0 0 166.667 20"
    enable-background="new 0 0 166.667 20"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M15.591,5.805c0.074,1.605-0.448,2.996-1.568,4.172c-1.12,1.176-2.492,1.764-4.116,1.764h-6.72V20H0.079
		V8.661h9.828h0.028c0.709,0,1.316-0.252,1.82-0.756c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82
		c-0.504-0.504-1.111-0.756-1.82-0.756H0.079V0.4h9.828c1.512,0,2.814,0.523,3.906,1.568c1.092,1.046,1.685,2.315,1.778,3.808V5.805
		z"
      />
      <path
        fill="currentColor"
        d="M18.39,0.4h15.12v3.108H18.39V0.4z M33.51,16.893V20H18.39V8.661h15.12v3.08H21.498v5.152H33.51z"
      />
      <path
        fill="currentColor"
        d="M48.799,20l-5.152-8.26h-4.228V20h-3.108V8.661h9.828h0.028c0.709,0,1.316-0.252,1.82-0.756
		c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82c-0.504-0.504-1.111-0.756-1.82-0.756h-9.856V0.4h9.828
		c1.512,0,2.814,0.523,3.906,1.568c1.092,1.046,1.685,2.315,1.778,3.808v0.028c0.056,1.4-0.35,2.655-1.218,3.766
		c-0.868,1.111-1.993,1.807-3.374,2.086L52.438,20H48.799z"
      />
      <path
        fill="currentColor"
        d="M69.406,2.192L67.39,4.601c-1.512-1.25-3.117-1.82-4.816-1.708c-0.598,0.056-1.139,0.196-1.624,0.42
		c-0.448,0.206-0.821,0.486-1.12,0.84c-0.43,0.523-0.626,1.158-0.588,1.904c0.056,0.299,0.191,0.579,0.406,0.84
		c0.214,0.262,0.438,0.476,0.672,0.644s0.574,0.341,1.022,0.518c0.448,0.178,0.816,0.308,1.106,0.392s0.714,0.21,1.274,0.378
		c0.915,0.262,1.61,0.472,2.086,0.63c0.476,0.159,1.068,0.42,1.778,0.784c0.709,0.364,1.232,0.751,1.568,1.162
		c0.336,0.411,0.616,0.957,0.84,1.639c0.224,0.682,0.299,1.47,0.224,2.365v0.028c-0.131,0.989-0.504,1.876-1.12,2.66
		c-0.747,0.915-1.754,1.577-3.024,1.987c-1.213,0.373-2.52,0.467-3.92,0.281c-2.427-0.299-4.732-1.326-6.916-3.08l1.96-2.437
		c1.717,1.362,3.491,2.155,5.32,2.38c0.97,0.131,1.838,0.074,2.604-0.168c0.672-0.205,1.176-0.522,1.512-0.952
		c0.243-0.298,0.392-0.644,0.448-1.036c0.018-0.373-0.052-0.714-0.21-1.021c-0.159-0.309-0.346-0.561-0.56-0.756
		c-0.215-0.196-0.551-0.393-1.008-0.588c-0.458-0.197-0.835-0.346-1.134-0.449c-0.299-0.102-0.742-0.237-1.33-0.405
		s-1.032-0.298-1.33-0.392c-0.299-0.094-0.714-0.238-1.246-0.435c-0.532-0.196-0.942-0.378-1.232-0.546s-0.63-0.392-1.022-0.672
		c-0.392-0.28-0.695-0.574-0.91-0.882c-0.215-0.308-0.416-0.681-0.602-1.12c-0.187-0.438-0.318-0.91-0.392-1.414V6.28
		c-0.112-1.586,0.326-2.977,1.316-4.172c0.579-0.69,1.306-1.241,2.184-1.652c0.858-0.392,1.773-0.625,2.744-0.7
		c0.765-0.056,1.54-0.009,2.324,0.14s1.586,0.416,2.408,0.798C67.904,1.077,68.678,1.577,69.406,2.192z"
      />
      <path
        fill="currentColor"
        d="M83.35-0.104c1.4,0,2.734,0.271,4.004,0.812c1.269,0.542,2.366,1.274,3.29,2.198
		c0.924,0.924,1.656,2.021,2.198,3.29c0.542,1.27,0.812,2.604,0.812,4.004c0,1.867-0.462,3.588-1.386,5.167
		c-0.924,1.577-2.175,2.827-3.752,3.752c-1.578,0.924-3.3,1.385-5.166,1.385c-1.867,0-3.594-0.461-5.18-1.385
		c-1.587-0.925-2.837-2.175-3.752-3.752c-0.915-1.578-1.372-3.3-1.372-5.167s0.458-3.588,1.372-5.166
		c0.915-1.577,2.165-2.828,3.752-3.752C79.756,0.358,81.483-0.104,83.35-0.104z M88.362,15.213c1.381-1.381,2.072-3.053,2.072-5.012
		c0-1.96-0.691-3.63-2.072-5.012C86.98,3.807,85.31,3.117,83.35,3.117s-3.631,0.69-5.012,2.072
		c-1.381,1.382-2.072,3.052-2.072,5.012c0,1.959,0.69,3.631,2.072,5.012s3.052,2.072,5.012,2.072S86.98,16.594,88.362,15.213z"
      />
      <path
        fill="currentColor"
        d="M112.61,20c-0.542,0-1.368,0-2.478,0c-1.111,0-1.946,0-2.506,0L99.562,3.592h-3.108V0.4h4.984l8.092,16.408
		V0.4h3.08V20z M99.562,20h-3.108V8.745h3.108V20z"
      />
      <path
        fill="currentColor"
        d="M131.566,20c-0.542,0-1.368,0-2.478,0c-1.111,0-1.946,0-2.506,0l-8.064-16.408h-3.108V0.4h4.984
		l8.092,16.408V0.4h3.08V20z M118.518,20h-3.108V8.745h3.108V20z"
      />
      <path
        fill="currentColor"
        d="M134.365,0.4h15.12v3.108h-15.12V0.4z M149.485,16.893V20h-15.12V8.661h15.12v3.08h-12.012v5.152H149.485z"
      />
      <path
        fill="currentColor"
        d="M166.565,20h-14.28V0.4h3.108v16.492h11.172V20z"
      />
    </g>
  </svg>
</template>