<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full h-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        class="w-full h-full"
      />
    </g>
    <g id="IASO_Logo">
      <path
        fill="currentColor"
        d="M265,205.659l-35.324,0.156c12.916-19.207,20.456-42.332,20.456-67.219
		c0-66.609-53.995-120.605-120.606-120.605c-66.608,0-120.604,53.996-120.604,120.605c0,25.232,7.753,48.65,21,68.014L-5,206.591
		V245h116.519c-8.997-1.824-18.048-4.906-26.66-9h0.135c-37.076-17.099-62.813-54.59-62.813-98.1
		c0-59.637,48.345-107.978,107.98-107.978c59.634,0,107.978,48.341,107.978,107.978c0,31.894-13.83,60.553-35.816,80.319
		c-7.797,6.893-16.837,12.855-26.452,17.527c-0.182,0.084-0.361,0.169-0.543,0.253h0.014c-8.562,4.108-17.563,7.197-26.539,9H265
		V205.659z"
      />
      <polygon
        fill="currentColor"
        points="64.187,72 64.283,190 82.967,190 82.871,72 	"
      />
      <path
        fill="currentColor"
        d="M162.331,72h-0.003H162.331H93.079l0.096,13h53.727c1.363,3,2.997,6,4.895,11H93v11h63.696
		c4.157,9,9.178,22,15.062,36H132.37l7.743-19h-20.536l-27.941,66h20.535l11.951-28h55.714l7.19,16H159v12h32.957h13.911h6.624
		L162.331,72z"
      />
    </g>
  </svg>
</template>

