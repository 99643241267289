
import { defineComponent, computed, PropType } from 'vue'
import { PaginationConfig } from '@/types/base'

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<PaginationConfig>, // do type for this
      default: () => ({}),
    },
    data: {
      type: Array,
      default: () => ([]),
    },
  },

  emits: ['paginate'],

  setup (props) { // watch out for int/string types on these numbers
    const pageLinks = computed(() => props.config.pages)

    const currentPage = computed(() => props.config.current_page)

    const perPage = computed(() => props.config.per_page)

    const hasMorePages = computed(() => currentPage.value < props.config.last_page)

    const hasPages = computed(() => currentPage.value !== 1 || hasMorePages.value)

    const onFirstPage = computed(() => currentPage.value <= 1)

    const nextPageNumber = computed(() => {
      if (!hasMorePages.value) return null

      return currentPage.value + 1
    })

    const previousPageNumber = computed(() => {
      if (currentPage.value === 1) return null

      return currentPage.value - 1
    })

    const firstItem = computed(() => props.data.length > 0 ? (currentPage.value - 1) * perPage.value + 1 : null)

    const lastItem = computed(() => props.data.length > 0 && firstItem.value ? firstItem.value + props.data.length - 1 : null)

    const total = computed(() => props.config.total)

    return {
      pageLinks,
      currentPage,
      hasMorePages,
      hasPages,
      onFirstPage,
      nextPageNumber,
      previousPageNumber,
      firstItem,
      lastItem,
      total,
    }
  },
})
