<template>
  <h3 class="text-blue-medium text-sm italic mb-1 block">
    <slot />
  </h3>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    return {}
  },
})
</script>