<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-auto"
    viewBox="0 0 147.447 20"
    enable-background="new 0 0 147.447 20"
    xml:space="preserve"
  >
    <g>
      <path
        fill="currentColor"
        d="M12.567,20l-5.152-8.26H3.187V20H0.079V8.661h9.828h0.028c0.709,0,1.316-0.252,1.82-0.756
		c0.504-0.504,0.756-1.11,0.756-1.82c0-0.709-0.252-1.316-0.756-1.82c-0.504-0.504-1.111-0.756-1.82-0.756H0.079V0.4h9.828
		c1.512,0,2.814,0.523,3.906,1.568c1.092,1.046,1.685,2.315,1.778,3.808v0.028c0.056,1.4-0.35,2.655-1.218,3.766
		c-0.868,1.111-1.993,1.807-3.374,2.086L16.207,20H12.567z"
      />
      <path
        fill="currentColor"
        d="M19.006,0.4h15.12v3.108h-15.12V0.4z M34.126,16.893V20h-15.12V8.661h15.12v3.08H22.114v5.152H34.126z"
      />
      <path
        fill="currentColor"
        d="M38.298,15.367c-0.915-1.578-1.372-3.3-1.372-5.167s0.458-3.588,1.372-5.166
		c0.915-1.577,2.165-2.828,3.752-3.752c1.586-0.924,3.313-1.386,5.18-1.386c1.4,0,2.734,0.271,4.004,0.812
		c1.269,0.542,2.366,1.274,3.29,2.198c0.924,0.924,1.656,2.021,2.198,3.29c0.542,1.27,0.812,2.604,0.812,4.004
		c0,2.127-0.598,4.069-1.792,5.824l1.792,1.735L55.378,20l-7.336-7.111l2.156-2.24l3.164,3.08c0.634-1.082,0.952-2.258,0.952-3.528
		c0-1.96-0.691-3.63-2.072-5.012c-1.381-1.381-3.052-2.072-5.012-2.072s-3.631,0.69-5.012,2.072
		c-1.381,1.382-2.072,3.052-2.072,5.012c0,1.959,0.69,3.631,2.072,5.012s3.052,2.072,5.012,2.072c0.298,0,0.588-0.02,0.868-0.057
		l2.716,2.633c-1.158,0.428-2.352,0.643-3.584,0.643c-1.867,0-3.594-0.461-5.18-1.385C40.463,18.194,39.213,16.944,38.298,15.367z"
      />
      <path
        fill="currentColor"
        d="M74.559,0.4v13.217c0,1.959-0.696,3.635-2.086,5.025c-1.391,1.391-3.066,2.086-5.026,2.086
		c-1.96,0-3.636-0.695-5.026-2.086s-2.086-3.066-2.086-5.025V0.4h3.108v13.217c0,1.102,0.392,2.043,1.176,2.827
		c0.784,0.784,1.726,1.177,2.828,1.177c1.101,0,2.044-0.393,2.828-1.177c0.784-0.784,1.176-1.726,1.176-2.827V0.4H74.559z"
      />
      <path
        fill="currentColor"
        d="M77.358,0.4h15.12v3.108h-15.12V0.4z M92.478,16.893V20h-15.12V8.661h15.12v3.08H80.466v5.152H92.478z"
      />
      <path
        fill="currentColor"
        d="M109.446,2.192l-2.016,2.408c-1.512-1.25-3.117-1.82-4.816-1.708c-0.598,0.056-1.139,0.196-1.624,0.42
		c-0.448,0.206-0.821,0.486-1.12,0.84c-0.43,0.523-0.626,1.158-0.588,1.904c0.056,0.299,0.191,0.579,0.406,0.84
		c0.214,0.262,0.438,0.476,0.672,0.644s0.574,0.341,1.022,0.518c0.448,0.178,0.816,0.308,1.106,0.392s0.714,0.21,1.274,0.378
		c0.915,0.262,1.61,0.472,2.086,0.63c0.476,0.159,1.068,0.42,1.778,0.784c0.709,0.364,1.232,0.751,1.568,1.162
		c0.336,0.411,0.616,0.957,0.84,1.639c0.224,0.682,0.299,1.47,0.224,2.365v0.028c-0.131,0.989-0.504,1.876-1.12,2.66
		c-0.747,0.915-1.754,1.577-3.024,1.987c-1.213,0.373-2.52,0.467-3.92,0.281c-2.427-0.299-4.732-1.326-6.916-3.08l1.96-2.437
		c1.717,1.362,3.491,2.155,5.32,2.38c0.97,0.131,1.838,0.074,2.604-0.168c0.672-0.205,1.176-0.522,1.512-0.952
		c0.243-0.298,0.392-0.644,0.448-1.036c0.018-0.373-0.052-0.714-0.21-1.021c-0.159-0.309-0.346-0.561-0.56-0.756
		c-0.215-0.196-0.551-0.393-1.008-0.588c-0.458-0.197-0.835-0.346-1.134-0.449c-0.299-0.102-0.742-0.237-1.33-0.405
		s-1.032-0.298-1.33-0.392c-0.299-0.094-0.714-0.238-1.246-0.435c-0.532-0.196-0.942-0.378-1.232-0.546s-0.63-0.392-1.022-0.672
		c-0.392-0.28-0.695-0.574-0.91-0.882c-0.215-0.308-0.416-0.681-0.602-1.12c-0.187-0.438-0.318-0.91-0.392-1.414V6.28
		c-0.112-1.586,0.326-2.977,1.316-4.172c0.579-0.69,1.306-1.241,2.184-1.652c0.858-0.392,1.773-0.625,2.744-0.7
		c0.765-0.056,1.54-0.009,2.324,0.14s1.586,0.416,2.408,0.798C107.943,1.077,108.718,1.577,109.446,2.192z"
      />
      <path
        fill="currentColor"
        d="M129.074,3.508h-15.988V0.4h15.988V3.508z M122.634,20h-3.108V6.617h3.108V20z"
      />
      <path
        fill="currentColor"
        d="M146.042,2.192l-2.016,2.408c-1.512-1.25-3.117-1.82-4.816-1.708c-0.598,0.056-1.139,0.196-1.624,0.42
		c-0.448,0.206-0.821,0.486-1.12,0.84c-0.43,0.523-0.626,1.158-0.588,1.904c0.056,0.299,0.191,0.579,0.406,0.84
		c0.214,0.262,0.438,0.476,0.672,0.644s0.574,0.341,1.022,0.518c0.448,0.178,0.816,0.308,1.106,0.392s0.714,0.21,1.274,0.378
		c0.915,0.262,1.61,0.472,2.086,0.63c0.476,0.159,1.068,0.42,1.778,0.784c0.709,0.364,1.232,0.751,1.568,1.162
		c0.336,0.411,0.616,0.957,0.84,1.639c0.224,0.682,0.299,1.47,0.224,2.365v0.028c-0.131,0.989-0.504,1.876-1.12,2.66
		c-0.747,0.915-1.754,1.577-3.024,1.987c-1.213,0.373-2.52,0.467-3.92,0.281c-2.427-0.299-4.732-1.326-6.916-3.08l1.96-2.437
		c1.717,1.362,3.491,2.155,5.32,2.38c0.97,0.131,1.838,0.074,2.604-0.168c0.672-0.205,1.176-0.522,1.512-0.952
		c0.243-0.298,0.392-0.644,0.448-1.036c0.018-0.373-0.052-0.714-0.21-1.021c-0.159-0.309-0.346-0.561-0.56-0.756
		c-0.215-0.196-0.551-0.393-1.008-0.588c-0.458-0.197-0.835-0.346-1.134-0.449c-0.299-0.102-0.742-0.237-1.33-0.405
		s-1.032-0.298-1.33-0.392c-0.299-0.094-0.714-0.238-1.246-0.435c-0.532-0.196-0.942-0.378-1.232-0.546s-0.63-0.392-1.022-0.672
		c-0.392-0.28-0.695-0.574-0.91-0.882c-0.215-0.308-0.416-0.681-0.602-1.12c-0.187-0.438-0.318-0.91-0.392-1.414V6.28
		c-0.112-1.586,0.326-2.977,1.316-4.172c0.579-0.69,1.306-1.241,2.184-1.652c0.858-0.392,1.773-0.625,2.744-0.7
		c0.765-0.056,1.54-0.009,2.324,0.14s1.586,0.416,2.408,0.798C144.539,1.077,145.313,1.577,146.042,2.192z"
      />
    </g>
  </svg>
</template>