import { StringMap } from "@/types/base";
import { EntryPillType, SubGrade, Grade } from "@/types/roster";

export const pillThemeMap: StringMap = {
  [EntryPillType.EARLY]: 'bg-entry-early text-white',
  [EntryPillType.MID]: 'bg-entry-mid text-white',
  [EntryPillType.LATE]: 'bg-entry-late text-white',
  [EntryPillType.NIGHT_ONE]: 'bg-entry-night1 text-white',
  [EntryPillType.NIGHT_TWO]: 'bg-entry-night2 text-white',
  [EntryPillType.ON_CALL]: 'bg-entry-on-call text-white',
  [EntryPillType.ABSENCE]: 'bg-entry-absence text-white',
  [EntryPillType.STUDY_LEAVE]: 'bg-entry-study text-white',
  [EntryPillType.SICKNESS]: 'bg-entry-sickness text-white',
  [EntryPillType.OFF]: 'bg-entry-off text-white',
}

export const subGradeMap: StringMap = {
  [SubGrade.CONS]: 'CONS',
  [SubGrade.REG_JNR]: 'R Jn',
  [SubGrade.REG_SNR]: 'R Sn',
  [SubGrade.ASOC]: 'AS',
  [SubGrade.SHO]: 'SHO',
  [SubGrade.NMP]: 'NMP',
}

export const gradeMap: StringMap = {
  [Grade.CONS]: 'CONS',
  [Grade.REG]: 'REG',
  [Grade.ASOC]: 'AS',
  [Grade.SHO]: 'SHO',
  [Grade.NMP]: 'NMP',
}