
import { defineComponent, computed } from 'vue'
import IasotechLogoIcon from '@/components/icons/IasotechLogoIcon.vue'

export default defineComponent({
  components: {
    IasotechLogoIcon,
  },

  setup(_, ctx) {
    const hasSubtitle = computed(() => !!ctx.slots.formSubtitle)

    return {
      hasSubtitle,
    }
  },
})
