<template>
  <header class="flex items-center justify-end shadow-md bg-white py-2 px-2 md:px-4 relative overflow-hidden h-12 md:h-16 flex-shrink-0">
    <span class="absolute top-0 left-2 hidden lg:block w-full xl:w-11/12 xxl:w-10/12 opacity-25 text-blue-light">
      <RotaArchitectLogoIcon />
    </span>
    
    <span
      v-if="pageHeaderIcon"
      class="hidden sm:block text-blue-dark mr-auto z-10 h-7"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <component :is="pageHeaderIcon" />
      </transition>
    </span>

    <p
      class="text-blue-medium italic block md:text-xl mr-2 md:mr-3"
    >
      {{ user.name }}
    </p>
    <button
      @click="toggleUserMenu"
      class="relative w-9 h-9 md:w-12 md:h-12 cursor-pointer active:outline-none bg-white focus:outline-none rounded-full flex border border-blue-medium"
    >
      <span class="absolute top-1/2 right-1/2 transform -translate-y-1/2 translate-x-1/2 w-9 md:w-12 h-9 md:h-12 flex items-center justify-center rounded-full text-blue-medium hover:text-blue-dark hover:border-6 border-gray-200 transition-all ease-out duration-300 font-bold text-xl md:text-2xl box-content origin-center">
        {{ initials }}
      </span>
    </button>
    <TheUserMenu @clicked="toggleUserMenu" />
  </header>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import nameToInitials from '@/utils/nameToInitials'
import { useRoute } from 'vue-router'

// Store
import { useStore } from 'vuex'
import { GetterTypes as UserGetters } from '@/store/user/getters'
import { GetterTypes as BaseGetters } from '@/store/base'
import { ActionTypes as BaseActions } from '@/store/base'

// Components
import TheUserMenu from '@/components/singletons/TheUserMenu.vue'

// Icons
import RotaArchitectLogoIcon from '@/components/icons/RotaArchitectLogoIcon.vue'
import DashboardHeaderIcon from '@/components/icons/headers/DashboardHeaderIcon.vue'
import PersonnelHeaderIcon from '@/components/icons/headers/PersonnelHeaderIcon.vue'
import MasterRotaHeaderIcon from '@/components/icons/headers/MasterRotaHeaderIcon.vue'
import DailyRotaHeaderIcon from '@/components/icons/headers/DailyRotaHeaderIcon.vue'
import RequestsHeaderIcon from '@/components/icons/headers/RequestsHeaderIcon.vue'
import MyProfileHeaderIcon from '@/components/icons/headers/MyProfileHeaderIcon.vue'


export default defineComponent({
  components: {
    RotaArchitectLogoIcon,
    DashboardHeaderIcon,
    PersonnelHeaderIcon,
    MasterRotaHeaderIcon,
    DailyRotaHeaderIcon,
    RequestsHeaderIcon,
    MyProfileHeaderIcon,
    TheUserMenu,
  },

  setup () {
    const store = useStore()
    const route = useRoute()

    const user = computed(() => store.getters[UserGetters.USER])

    const toggleUserMenu = () => {
      store.getters[BaseGetters.USER_MENU_OPEN]
        ? store.dispatch(BaseActions.HIDE_USER_MENU)
        : store.dispatch(BaseActions.SHOW_USER_MENU)
    }

    const pageHeaderIcon = computed(() => `${String(route.name)}-header-icon`)

    return {
      toggleUserMenu,
      user,
      initials: nameToInitials(user.value.name),
      pageHeaderIcon,
    }
  },
})
</script>
