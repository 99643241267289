
import { StringMap } from '@/types/base'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: String,
      validator: (val: string) => ['md', 'full'].includes(val),
      default: 'full',
    },
  },


  setup (props) {
    const sizeMap: StringMap = {
      'md': 'w-40',
      'full': 'w-64',
    }

    return {
      sizeClasses: sizeMap[props.size],
    }
  },
})
