<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 260 260"
    enable-background="new 0 0 260 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <g id="Positive_Toast">
      <path
        fill="#FFFFFF"
        d="M258.793,129.894c0,71.187-57.712,128.897-128.896,128.897C58.707,258.791,1,201.081,1,129.894
		C1,58.709,58.707,1,129.896,1C201.081,1,258.793,58.709,258.793,129.894z"
      />
      <polyline
        fill="none"
        stroke="#49B649"
        stroke-width="22"
        stroke-miterlimit="10"
        points="53.364,141.734 93.28,185.429 
		212.001,84.718 	"
      />
    </g>
  </svg>
</template>