<template>
  <teleport to="body">
    <div
      v-if="toastsArray.length"
      class="fixed z-50 right-0 mr-2 bottom-2 md:bottom-auto md:top-20 flex flex-col justify-end sm:justify-start items-center sm:items-end pointer-events-none w-76 md:w-toast"
    >
      <div
        v-for="toast in toastsArray"
        :key="toast.uuid"
        class="w-full shadow-lg rounded-sm pointer-events-auto mb-3 transition-opacity ease-linear duration-300"
        :class="{
          'opacity-0': toast.removing || toast.new,
          'opacity-100': !toast.removing && !toast.new,
          'bg-green-medium': toast.type === 'success',
          'bg-red-medium': toast.type === 'error',
          'bg-blue-medium': toast.type === 'info',
          'bg-orange-medium': toast.type === 'warning',
        }"
      >
        <div class="rounded-sm shadow-xs w-full">
          <div class="flex-1 flex items-center relative p-2 md:p-3">
            <span class="w-8 md:w-12 h-8 md:h-12 flex items-center justify-center mr-3">
              <component :is="`${toast.type}-icon`" />
            </span>
            <section class="text-white">
              <header class="text-base md:text-lg font-bold leading-snug">
                {{ toast.heading }}
              </header>
              <p class="text-sm">
                {{ toast.content }}
              </p>
            </section>
            <button
              class="absolute top-2 right-2 text-white active:outline-none focus:outline-none w-4 h-4 flex"
              @click="hideToastNotification(toast.uuid)"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { 
  GetterTypes as ToastGetters,
  ActionTypes as ToastActions,
 } from '@/store/toast'

// Icons
import WarningIcon from '@/components/icons/toast/WarningIcon.vue'
import ErrorIcon from '@/components/icons/toast/ErrorIcon.vue'
import InfoIcon from '@/components/icons/toast/InfoIcon.vue'
import SuccessIcon from '@/components/icons/toast/SuccessIcon.vue'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'

export default defineComponent({
  components: {
    WarningIcon,
    ErrorIcon,
    InfoIcon,
    SuccessIcon,
    CloseIcon,
  },

  setup () {
    const store = useStore()

    const toastsArray = computed(() => store.getters[ToastGetters.ALL])

    const hideToastNotification = (uuid: string) => {
      store.dispatch(ToastActions.HIDE, uuid)
    }

    return {
      toastsArray,
      hideToastNotification,
    }
  },
})
</script>