<template>
  <div
    v-if="loadingOrHasOptions"
    :class="wrapperClasses"
  >
    <label :class="labelClasses">
      <span
        v-if="loadingOptions"
        class="h-5 block pulse w-full bg-blue-lightest rounded-lg"
      />
      <span v-else>
        {{ label }}
      </span>
    </label>
    <div
      v-if="loadingOptions"
      class="block pulse w-full bg-blue-lightest rounded-lg"
      :class="[inputSizeClasses, { 
        'mb-3': !!$slots.message,
      }]"
    />
    <div
      v-else
      class="w-full"
    >
      <div
        class="relative rounded-lg w-full border-2"
        :class="[inputThemeClasses, inputSizeClasses, borderClasses, { 
          'pl-8' : !!$slots.icon,
        }]"
      >
        <div
          v-if="!!$slots.icon"
          class="absolute inset-y-0 left-2 flex items-center pointer-events-none"
        >
          <span
            class="w-4 h-4 flex"
            :class="iconColorClasses"
          >
            <slot name="icon" />
          </span>
        </div>
        <select
          :value="modelValue"
          ref="select"
          class="block placeholder-gray-300 focus:outline-none w-full rounded-lg h-full px-2"
          :class="[
            inputThemeClasses, inputSizeClasses,
          ]"
          v-bind="{ ...$attrs }"
          @input="$emit('update:modelValue', $event.target.value)"
        >
          <option
            disabled
            value=""
          >
            ...
          </option>
          <option
            v-for="option in options"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
        <button
          v-if="modelValue !== ''"
          class="absolute inset-y-0 right-6 h-3 top-1/2 transform -translate-y-1/2 flex items-center active:outline-none"
          :class="closeButtonClasses"
          @click="$emit('update:modelValue', '')"
        >
          <span class="w-3 h-3 flex">
            <CloseIcon />
          </span>
        </button>
      </div>
      <slot name="message" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { SelectOption } from '@/types/form'
import CloseIcon from '@/components/icons/toast/CloseIcon.vue'
import { StringMap } from '@/types/base'

export default defineComponent({
  inheritAttrs: false,

  components: {
    CloseIcon,
  },
  
  props: {
    loadingOptions: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    theme: {
      type: String,
      validator: (val: string) => ['filter', 'base'].includes(val),
      default: 'filter',
    },
    size: {
      type: String,
      validator: (val: string) => ['sm', 'full'].includes(val),
      default: 'full',
    },
    valid: {
      type: Boolean,
      default: null,
    },
  },

  emits: ['update:modelValue'],

  setup(props) {

    const loadingOrHasOptions = computed(() => props.loadingOptions || !!props.options.length)

    const wrapperClasses: StringMap = {
      filter: 'w-full',
      base: 'w-full md:flex',
    }

    const labelClasses: StringMap = {
      filter: 'text-blue-medium text-sm italic mb-1 block',
      base: 'w-1/3 font-bold text-right italic text-gray-900 mt-3 mr-4',
    }

    const inputThemeClasses: StringMap = {
      filter: 'bg-blue-medium text-white',
      base: 'bg-white text-gray-900',
    }

     const inputSizeClasses: StringMap = {
      sm: 'h-8 text-sm',
      full: 'h-10',
    }

    const closeButtonClasses: StringMap = {
      filter: 'text-white hover:text-gray-300',
      base: 'text-blue-medium hover:text-blue-dark',
    }

    const iconColorClasses: StringMap = {
      filter: 'text-white',
      base: 'text-blue-medium',
    }

    const borderColorMap: StringMap = {
      filter: 'border-blue-medium',
      base: 'border-gray-300',
    }

    const borderClasses = computed(() => {
      if (props.valid) return 'border-green-medium'
      return borderColorMap[props.theme]
    })

    return {
      loadingOrHasOptions,
      wrapperClasses: wrapperClasses[props.theme],
      labelClasses: labelClasses[props.theme],
      inputThemeClasses: inputThemeClasses[props.theme],
      inputSizeClasses: inputSizeClasses[props.size],
      closeButtonClasses: closeButtonClasses[props.theme],
      iconColorClasses: iconColorClasses[props.theme],
      borderClasses,
    }
  },
})
</script>